import client from "./index";
import { routes } from "./../_api/routes";
import {
  INVESTED_LIST,
  PAGINATION,
  LOADING,
  LOADING_DONE, ERROR
} from "../actions/types";
import {extractServerErrors} from "../_utils/validate";

export const getInvestedList = page => async dispatch => {
  dispatch({
    type: LOADING,
    payload: {
      URL: `${routes.investedList}?page=${page}`,
      method: "get",
      data: page
    }
  });
  try {
    const response = await client.get(`${routes.investedList}?page=${page}`);
    dispatch({
      type: INVESTED_LIST,
      payload: response.data.data
    });
    dispatch({
      type: PAGINATION,
      payload: response.data.meta
    });
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
            typeof e.response === "undefined"
                ? e.message
                : extractServerErrors(e.response.data)
      });
    }
  }finally {
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.investedList}?page=${page}`,
        method: "get",
        data: page
      }
    });
  }
};
