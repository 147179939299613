import React, { Component } from "react";
import { connect } from "react-redux";
import { getProductsList } from "../../actions/product";
import { getCreditRequestList } from "../../actions/credits";
import { getInvestedList } from "../../actions/invested";
import { getApplicationList } from "../../actions/application";
class Pagination extends Component {
  state = { currentPage: 1 };
  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentPage !== prevState.currentPage) {
      this.props.url(this.state.currentPage);
    }
  }
  renderPage = () => {
    const pageLinks = [];

    for (let i = 1; i <= this.props.totalPage; i++) {
      pageLinks.push(
        <li
          key={i}
          className={
            this.state.currentPage === i ? "page-item active" : "page-item"
          }
        >
          <button
            className="page-link"
            onClick={() => {
              this.setState({ currentPage: i });
            }}
          >
            {i}
          </button>
        </li>
      );
    }

    return pageLinks;
  };
  render() {
    const { totalPage } = this.props;
    const { currentPage } = this.state;
    return (
      <div className="content-footer">
        <nav className="nav-content">
          <ul className="pagination">
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => {
                  if (currentPage > 1)
                    this.setState({ currentPage: currentPage - 1 });
                }}
              >
                <i className="bx bx-chevron-left" />
              </button>
            </li>
            {this.renderPage()}
            {/* <li className={currentPage === 1 ? `page-item active` : 'page-item'}>
              <button className="page-link" onClick={() => this.setState({ currentPage: 1 })}>
                1
                </button>
            </li>
            {totalPage > 1 ? <li className={currentPage === 2 ? `page-item active` : 'page-item'}>
              <button className="page-link" onClick={() => this.setState({ currentPage: 2 })}>
                2
                </button>
            </li> : null}

            {totalPage > 2 ? <li className={currentPage === 3 ? `page-item active` : 'page-item'}>
              <button className="page-link" onClick={() => this.setState({ currentPage: 3 })}>
                3
                </button>
            </li> : null}
              {totalPage > 3 ? <ul className="pagination"><li>...</li></ul> : null}
              {currentPage > 3  ? <ul className="pagination">
                <li>...</li>
                <li className={currentPage > 3  ? `page-item active` : 'page-item'}>
                  <button className="page-link">{currentPage}</button>
                </li>
                <li>...</li></ul>: null} */}
            {/* {totalPage ? <li className={currentPage === totalPage ? `page-item active` : 'page-item'}>
              <button className="page-link" onClick={() => this.setState({ currentPage: totalPage })}>{totalPage}</button>
            </li> : null} */}
            {/* {totalPage > 3 ? <ul className="pagination"><li>...</li><li className="page-item">
               <button className="page-link" onClick={()=> {
                 if(currentPage==3 || currentPage > 3 ){
                  this.setState({ currentPage: currentPage + 1 }) 
                 }
               }}>
                <i className="bx bx-chevron-right" />
              </button>
            </li></ul> : null} */}
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => {
                  if (currentPage < totalPage)
                    this.setState({ currentPage: currentPage + 1 });
                }}
              >
                <i className="bx bx-chevron-right" />
              </button>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default connect(null, {
  getProductsList,
  getCreditRequestList,
  getInvestedList,
  getApplicationList
})(Pagination);
