import Translate from "react-translate-component";
import {Field} from "redux-form";
import React from "react";

export const renderRatings = (ratings,language) => {
    return ratings.map((credit, id) => {
        return (
            <div
                className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6"
                key={credit.id}
            >
                {credit.id < 3 ? (
                    <div className="row">
                        <div className="col-6 col-md-6">
                            <label>{<Translate content="label.ratingaggentur"/>}</label>
                        </div>
                        <div className="col-6 col-md-6 text-right">
                            <label>{<Translate content="label.Kreditrating"/>}</label>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <div className="form-group form-group-inline">
                    <div className="form-check form-check-inline">
                        <Field
                            className="form-check-input"
                            type="checkbox"
                            name={`checkbox_${credit.id}`}
                            component="input"
                        />
                        <label className="form-check-label" htmlFor={id}>
                            {" "}
                            {credit.name[`${language}`]}
                        </label>
                    </div>
                    <Field
                        className="form-control"
                        type="text"
                        name={`cr_value_${credit.id}`}
                        component="input"
                    />
                </div>
            </div>
        );
    });
};
