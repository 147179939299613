import React, {Component, Fragment} from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import InputRange from "react-input-range";
import ReactTooltip from "react-tooltip";
import {fetchRatings} from "../../actions/ratings";
import {connect} from "react-redux";
import {addProduct} from "../../actions/product";
import {getIndustryList} from "../../actions/industry";
import {getServiceList} from "../../actions/service";
import {getAllState, getCounties} from "../../actions/statesCounties";
import {clearError, clearLoading} from "../../actions/clearError";
import Subheader from "../general/Subheader";
import * as validation from "../../_utils/validate";
import {displayServerErrors} from "../../_utils/validate";
import Translate from "react-translate-component";
import * as flashMessage from "../../actions/flashMessage";

import {dropDownField, inputField, inputSlider, radioButton, ReactUploader, renderMultiselect} from "../../_formFields";
import Loading from "../general/Loading";
import counterpart from "counterpart";
import {renderRatings} from "./ratings";
import IncompleteProfileMessage from "../general/IncompleteProfileMessage";
import {formSubmitFailError} from "../../_utils/validate";
import en from "../../_locale/en";
import de from "../../_locale/de";

const maximumCreditValueLimit = 5000000;
const minimumCreditValueLimit = 250000;
const minimumSalesLimit = 0;
const minimumNetProfit = 0;
const maximumNetProfit = 5000000;

class AddProduct extends Component {
    state = {
        value: {min: 12, max: 60},
        maxCreditAmount: maximumCreditValueLimit,
        minCreditAmount: minimumCreditValueLimit,
        minimumSalesAmount: minimumSalesLimit,
        minNetProfit: minimumNetProfit,
        files: [],
    };

    async componentDidMount() {
        await this.props.clearError();
        await this.props.clearLoading();
        await this.props.getIndustryList();
        await this.props.getServiceList();
        await this.props.getAllState();
        await this.props.fetchRatings();
    }

    onSubmit = formProps => {
        const ratings = [];
        this.props.ratings.forEach(credit => {
            if (formProps[`checkbox_${credit.id}`]) {
                ratings.push({rating_id: credit.id, value: formProps[`cr_value_${credit.id}`]});
            }
        });
        formProps.ratings = ratings;

        formProps.state_ids = (formProps.states.find(item => item.id === 0)) ?
            this.props.allStates.all.map(item => item.id) : formProps.states.map(item => item.id);

        formProps.county_ids = (formProps.counties.find(item => item.id === 0)) ?
            this.props.county.list.map(item => item.id) : formProps.counties.map(item => item.id);

        formProps.industry_id = (formProps.industries.find(item => item.id === 0)) ?
            this.props.industry.list.map(item => item.id) : formProps.industries.map(item => item.id);

        formProps.min_time_duration = this.state.value.min;
        formProps.max_time_duration = this.state.value.max;
        formProps.max_credit_amount = this.state.maxCreditAmount;
        formProps.min_credit_amount = this.state.minCreditAmount;
        formProps.min_sales_creditor = this.state.minimumSalesAmount;
        formProps.min_net_profit = this.state.minNetProfit;

        this.props.addProduct(formProps, () => {
            this.props.history.push("/products");
            this.props.showFlashMessage("alert.product_add");
            setTimeout(() => {
                this.props.hideFlashMessage();
            }, 5000);
        });
    };

    onStateChangeHandler = (value) => {
        const selectedStateList = [...value];
        const selectedStateIdList = (selectedStateList.find(item => item.id === 0)) ? this.props.allStates.all.map(item => item.id)
            : selectedStateList.map(item => item.id);
        this.props.getCounties(selectedStateIdList);
    };

    onBlurRangeLimiter(e, property, maximumLimit, minimumLimit) {
        if (e.target.value < minimumLimit) {
            this.setState({[property]: minimumLimit});
        } else if (e.target.value > maximumLimit) {
            this.setState({[property]: maximumLimit});
        } else {
            this.setState({[property]: e.target.value});
        }
    }

    render() {
        const {handleSubmit, rating_for_credit, submitFailed} = this.props;
        const industryList = this.props.industry.list.map(industry => ({
                id: industry.id,
                name: industry.name[this.props.language]
            }))
        ;
        industryList.unshift({
            id: 0,
            name: this.props.language === 'en' ? en.placeholder.selectAll : de.placeholder.selectAll
        });
        return (
            <Fragment>
                <Subheader heading={<Translate content="button.addnewproduct"/>}/>
                <div className="content-body">
                    <Loading/>
                    <form className="form-signup" onSubmit={handleSubmit(this.onSubmit)}>
                        <IncompleteProfileMessage/>
                        <div className="row mt-4">
                            <div className="col-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="amount">
                                        <strong>
                                            <Translate content="label.producttitle"/>
                                        </strong>
                                    </label>
                                    <i data-tip data-for="product" className=" bx bx-info-circle">
                                        <ReactTooltip id="product" type="info">
                                            <Translate content="tooltip.productName"/>
                                        </ReactTooltip>
                                    </i>
                                    <Field
                                        name="product_title"
                                        type="text"
                                        component={inputField}
                                        className="form-control"
                                        validate={validation.required}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="amount">
                                        <strong>
                                            {" "}
                                            <Translate content="label.state"/>
                                        </strong>
                                    </label>
                                    <i data-tip data-for="state" className=" bx bx-info-circle">
                                        <ReactTooltip id="state" type="info">
                                            <Translate content="tooltip.states"/>
                                        </ReactTooltip>
                                    </i>
                                    <Field
                                        name="states"
                                        component={renderMultiselect}
                                        data={this.props.allStates.all}
                                        textField="name"
                                        valueField="id"
                                        validate={validation.required}
                                        placeholder={counterpart.translate("placeholder.select")}
                                        onChange={this.onStateChangeHandler}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="amount">
                                        <strong>
                                            {" "}
                                            <Translate content="label.service"/>
                                        </strong>
                                    </label>
                                    <i data-tip data-for="service" className=" bx bx-info-circle">
                                        <ReactTooltip id="service" type="info">
                                            <Translate content="tooltip.service"/>
                                        </ReactTooltip>
                                    </i>
                                    <Field
                                        name="services"
                                        component={dropDownField}
                                        options={this.props.service[`${this.props.language}`]}
                                        validate={validation.required}
                                        placeholder={<Translate content="placeholder.select"/>}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="amount">
                                        <strong>
                                            {" "}
                                            <Translate content="label.county"/>
                                        </strong>
                                    </label>
                                    <i data-tip data-for="county" className=" bx bx-info-circle">
                                        <ReactTooltip id="county" type="info">
                                            <Translate content="tooltip.county"/>
                                        </ReactTooltip>
                                    </i>
                                    <Field
                                        name="counties"
                                        component={renderMultiselect}
                                        data={this.props.county.list}
                                        textField="name"
                                        valueField="id"
                                        validate={validation.required}
                                        placeholder={counterpart.translate("placeholder.select")}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12 col-sm-12 col-md-6">
                                <label htmlFor="amount">
                                    <strong>
                                        {" "}
                                        <Translate content="label.industries"/>
                                    </strong>
                                </label>
                                <i data-tip data-for="industry" className=" bx bx-info-circle">
                                    <ReactTooltip id="industry" type="info">
                                        <Translate content="tooltip.industry"/>
                                    </ReactTooltip>
                                </i>
                                <Field
                                    name="industries"
                                    component={renderMultiselect}
                                    data={industryList}
                                    textField="name"
                                    valueField="id"
                                    className="form-group"
                                    placeholder={counterpart.translate("placeholder.select")}
                                />
                            </div>
                            <div className="col-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="amount">
                                        <strong>
                                            <Translate content="label.timeduration"/>
                                        </strong>
                                    </label>
                                    <i
                                        data-tip
                                        data-for="time-duration"
                                        className=" bx bx-info-circle"
                                    >
                                        <ReactTooltip id="time-duration" type="info">
                                            <Translate content="tooltip.timeDuration"/>
                                        </ReactTooltip>
                                    </i>
                                    <div className="form-group-ranger">
                                        <input
                                            className="form-control minimum_duration"
                                            type="number"
                                            id="minimum_duration"
                                            value={this.state.value.min}
                                            placeholder="3"
                                            onChange={e => {
                                                this.setState({
                                                    value: {
                                                        min:
                                                            e.target.value < parseInt(this.state.value.max)
                                                                ? e.target.value
                                                                : parseInt(this.state.value.max) - 1,
                                                        max: parseInt(this.state.value.max)
                                                    }
                                                });
                                            }}
                                            onBlur={e => {
                                                if (e.target.value < 12) {
                                                    this.setState({
                                                        value: {min: 12, max: this.state.value.max}
                                                    });
                                                } else if (e.target.value > 60) {
                                                    this.setState({
                                                        value: {
                                                            min: this.state.value.max - 1,
                                                            max: this.state.value.max
                                                        }
                                                    });
                                                } else {
                                                    this.setState({
                                                        value: {
                                                            min: e.target.value,
                                                            max: this.state.value.max
                                                        }
                                                    });
                                                }
                                            }}
                                        />
                                        &nbsp;&nbsp;
                                        <InputRange
                                            maxValue={60}
                                            minValue={12}
                                            value={this.state.value}
                                            onChange={value => this.setState({value})}
                                        />
                                        &nbsp;&nbsp;
                                        <input
                                            className="form-control maximum_duration"
                                            type="number"
                                            id="maximum_duration"
                                            name="maximum_duration"
                                            value={this.state.value.max}
                                            // validate={validation.required}
                                            placeholder="3"
                                            onChange={e => {
                                                this.setState({
                                                    value: {
                                                        max: e.target.value,
                                                        min: this.state.value.min
                                                    }
                                                });
                                            }}
                                            onBlur={e => {
                                                if (e.target.value < parseInt(this.state.value.min)) {
                                                    this.setState({
                                                        value: {
                                                            max: parseInt(this.state.value.min) + 1,
                                                            min: this.state.value.min
                                                        }
                                                    });
                                                } else if (e.target.value > 60) {
                                                    this.setState({
                                                        value: {
                                                            max: 60,
                                                            min: parseInt(this.state.value.min)
                                                        }
                                                    });
                                                } else {
                                                    this.setState({
                                                        value: {
                                                            max: e.target.value,
                                                            min: parseInt(this.state.value.min)
                                                        }
                                                    });
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}

                        <div className="row mt-4">
                            <div className="col-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="amount">
                                        <strong>
                                            {" "}
                                            <Translate content="label.mincredit"/>
                                        </strong>
                                    </label>
                                    <i
                                        data-tip
                                        data-for="min-credit"
                                        className=" bx bx-info-circle"
                                    >
                                        <ReactTooltip id="min-credit" type="info">
                                            <Translate content="tooltip.minCreditAmount"/>
                                        </ReactTooltip>
                                    </i>
                                    <div className="form-group-ranger">
                                        <Field
                                            type="range"
                                            component={inputSlider}
                                            id="min_credit_amount"
                                            name="min_credit"
                                            min={minimumCreditValueLimit}
                                            max={maximumCreditValueLimit}
                                            step="10000"
                                            defaultValue={this.state.minCreditAmount}
                                            onChange={e => {
                                                this.setState({minCreditAmount: e.target.value});
                                            }}
                                        />
                                        €
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="min_credit_amount"
                                            id="min_credit_amount_value"
                                            value={this.state.minCreditAmount}
                                            // validate={validation.required}
                                            placeholder={250000}
                                            min={minimumCreditValueLimit}
                                            max={maximumCreditValueLimit}
                                            step={10000}
                                            onChange={e => {
                                                this.setState({minCreditAmount: e.target.value});
                                            }}
                                            onBlur={e =>
                                                this.onBlurRangeLimiter(
                                                    e,
                                                    "minCreditAmount",
                                                    maximumCreditValueLimit,
                                                    minimumCreditValueLimit
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="amount">
                                        <strong>
                                            {" "}
                                            <Translate content="label.maxcredit"/>
                                        </strong>
                                    </label>
                                    <i
                                        data-tip
                                        data-for="max-credit"
                                        className=" bx bx-info-circle"
                                    >
                                        <ReactTooltip id="max-credit" type="info">
                                            <Translate content="tooltip.maxCreditAmount"/>
                                        </ReactTooltip>
                                    </i>
                                    <div className="form-group-ranger">
                                        {
                                            <Field
                                                type="range"
                                                className="w-100"
                                                component={inputSlider}
                                                id="max_credit_amount"
                                                name="max_credit"
                                                min={minimumCreditValueLimit}
                                                max={maximumCreditValueLimit}
                                                step="10000"
                                                defaultValue={this.state.maxCreditAmount}
                                                onChange={e => {
                                                    this.setState({maxCreditAmount: e.target.value});
                                                }}
                                            />
                                        }
                                        €
                                        <input
                                            className="form-control"
                                            name="max_credit_amount"
                                            type="number"
                                            id="amount"
                                            value={this.state.maxCreditAmount}
                                            // validate={validation.required}
                                            placeholder="5000000"
                                            step={10000}
                                            min={minimumCreditValueLimit}
                                            max={maximumCreditValueLimit}
                                            onChange={e => {
                                                this.setState({maxCreditAmount: e.target.value});
                                            }}
                                            onBlur={e =>
                                                this.onBlurRangeLimiter(
                                                    e,
                                                    "maxCreditAmount",
                                                    maximumCreditValueLimit,
                                                    minimumCreditValueLimit
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="amount">
                                        <strong>
                                            {" "}
                                            <Translate content="label.minimumsales"/>
                                        </strong>
                                    </label>
                                    <i
                                        data-tip
                                        data-for="min-sales"
                                        className=" bx bx-info-circle"
                                    >
                                        <ReactTooltip id="min-sales" type="info">
                                            <Translate content="tooltip.minSales"/>
                                        </ReactTooltip>
                                    </i>
                                    <input
                                        className="form-control"
                                        type="number"
                                        id="min_sales_creditor"
                                        name="min_sales_creditor"
                                        min="0"
                                        max="50000000000"
                                        step="100000"
                                        value={this.state.minimumSalesAmount}
                                        // validate={validation.required}
                                        placeholder="0"
                                        onChange={e => {
                                            if (e.target.value > 50000000000) {
                                                this.setState({
                                                    minimumSalesAmount: 50000000000
                                                });
                                            } else {
                                                this.setState({
                                                    minimumSalesAmount:
                                                        e.target.value < 0 ? 0 : e.target.value
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="amount">
                                        <strong>
                                            {" "}
                                            <Translate content="label.minNetProfit"/>
                                        </strong>
                                    </label>
                                    <i
                                        data-tip
                                        data-for="min-net-profit"
                                        className=" bx bx-info-circle"
                                    >
                                        <ReactTooltip id="min-net-profit" type="info">
                                            <Translate content="tooltip.min_net_profit"/>
                                        </ReactTooltip>
                                    </i>
                                    <div className="form-group-ranger">
                                        {
                                            <Field
                                                type="range"
                                                className="w-100"
                                                component={inputSlider}
                                                id="min_net_profit"
                                                name="min_net_profit"
                                                min={minimumNetProfit}
                                                max={maximumNetProfit}
                                                step="10000"
                                                defaultValue={this.state.minNetProfit}
                                                onChange={e => {
                                                    this.setState({minNetProfit: e.target.value});
                                                }}
                                            />
                                        }
                                        €
                                        <input
                                            className="form-control"
                                            name="min_net_profit"
                                            type="number"
                                            id="min_net_profit_value"
                                            value={this.state.minNetProfit}
                                            // validate={validation.required}
                                            placeholder="0"
                                            step={10000}
                                            min={minimumNetProfit}
                                            max={maximumNetProfit}
                                            onChange={e => {
                                                this.setState({minNetProfit: e.target.value});
                                            }}
                                            onBlur={e =>
                                                this.onBlurRangeLimiter(
                                                    e,
                                                    "minNetProfit",
                                                    maximumNetProfit,
                                                    minimumNetProfit
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12 col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label className="d-block">
                                        <strong>
                                            <Translate content="label.Sicherheiten"/>
                                        </strong>
                                        <i
                                            data-tip
                                            data-for="collateral"
                                            className=" bx bx-info-circle"
                                        >
                                            <ReactTooltip id="collateral" type="info">
                                                <Translate content="tooltip.collateral"/>
                                            </ReactTooltip>
                                        </i>
                                    </label>
                                    <div className="form-check form-check-inline">
                                        <Field
                                            type="radio"
                                            component={radioButton}
                                            value="true"
                                            name="collatoral"
                                            className="form-check-input"
                                            id="credit_true"
                                        />
                                        <Translate
                                            content="label.yes"
                                            component="label"
                                            className="form-check-label"
                                            htmlFor="rating-credit-yes"
                                        />
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <Field
                                            type="radio"
                                            component={radioButton}
                                            value="false"
                                            name="collatoral"
                                            className="form-check-input"
                                            id="credit_false"
                                        />
                                        <Translate
                                            content="label.no"
                                            component="label"
                                            className="form-check-label"
                                            htmlFor="rating-credit-no"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-lg-12 col-xl-6 p-0">
                                <div>
                                    <div className="form-group">
                                        <label className="d-block">
                                            <strong>
                                                <Translate content="label.ratingForCredit"/>
                                            </strong>
                                            <i
                                                data-tip
                                                data-for="rating"
                                                className=" bx bx-info-circle"
                                            >
                                                <ReactTooltip id="rating" type="info">
                                                    <Translate content="tooltip.rating"/>
                                                </ReactTooltip>
                                            </i>
                                        </label>
                                        <div className="form-check form-check-inline">
                                            <Field
                                                type="radio"
                                                component={radioButton}
                                                value="true"
                                                name="rating_for_credit"
                                                className="form-check-input"
                                                id="rating_for_credit_true"
                                            />
                                            <Translate
                                                content="label.yes"
                                                component="label"
                                                className="form-check-label"
                                                htmlFor="rating-credit-yes"
                                            />
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <Field
                                                type="radio"
                                                component={radioButton}
                                                value="false"
                                                name="rating_for_credit"
                                                className="form-check-input"
                                                id="rating_for_credit_false"
                                            />
                                            <Translate
                                                content="label.no"
                                                component="label"
                                                className="form-check-label"
                                                htmlFor="rating-credit-no"
                                            />
                                        </div>
                                    </div>
                                    {rating_for_credit === "true" ? (
                                        <div className="rating">
                                            <div
                                                className="row">{renderRatings(this.props.ratings, this.props.language)}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <div className="form-group">
                                    <strong>
                                        {" "}
                                        <Translate content="label.fileupload" component="label"/>
                                    </strong>
                                    <i data-tip data-for="files" className=" bx bx-info-circle">
                                        <ReactTooltip id="files" type="info">
                                            <Translate content="tooltip.documents"/>
                                        </ReactTooltip>
                                    </i>
                                    <Field
                                        name="files"
                                        component={ReactUploader}
                                        type="file"
                                        className="file-uploader file-uploader--small dropzone"
                                    />
                                </div>
                            </div>
                        </div>
                        {this.props.errMsg ? displayServerErrors(this.props.errMsg) : null}
                        {formSubmitFailError(submitFailed)}
                        <div className="row mt-4">
                            <div className="col">
                                <Translate
                                    content="button.submit"
                                    component="button"
                                    className="btn btn-primary btn-form"
                                    type="submit"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </Fragment>
        );
    }
}

function validate(values) {
    return {
        min_credit_amount: validation.required(values.min_credit_amount),
        max_credit_amount: validation.required(values.max_credit_amount)
    };
}

AddProduct = reduxForm({
    validate,
    form: "addProduct"
})(AddProduct);

const selector = formValueSelector("addProduct");

function mapStateToProps(state) {
    return {
        allStates: state.allStates,
        county: state.county,
        industry: state.industryList,
        service: state.service,
        ratings: state.ratings.ratings.data,
        language: state.language,
        errMsg: state.errors,
        rating_for_credit: selector(state, "rating_for_credit"),
        initialValues: {
            collatoral: "false",
            rating_for_credit: "false"
        },
    };
}

export default connect(mapStateToProps, {
    getAllState,
    getServiceList,
    getCounties,
    getIndustryList,
    fetchRatings,
    addProduct,
    clearError,
    clearLoading,
    ...flashMessage
})(AddProduct);
