import en from "./../_locale/en";
import de from "./../_locale/de";

const Translator = require("react-translate-component");

export const extractNames = list => list.map(l => (l.label ? l.label : l.name));

export const extractNamesForMultipleLocales = list =>
  list.map(l => (l.label ? l.label : l.name[Translator.getLocale()]));

export const extractId = (list, mainList) => {
  let r = [];
  if (list) {
    list.forEach(name => {
      mainList.forEach(main => {
        if (main.id) {
          if (name === main.label) {
            r.push(main.id);
          }
        } else if (main.value) {
          if (name === main.label) {
            r.push(main.value);
          }
        }
      });
    });
  } else {
    mainList.forEach(m => {
      if (m.id !== "undefined") {
        r.push(m.id);
      }
    });
  }

  return r;
};

export const splitService = array => {
  var english = [];
  var german = [];
  array.forEach(arr => {
    english.push({ value: arr.id, label: arr.name.en });
    german.push({ value: arr.id, label: arr.name.de });
  });
  return { en: english, de: german };
};

export const splitIndustries = array => {
  let english = [en.placeholder.selectAll];
  let german = [de.placeholder.selectAll];
  array.forEach(arr => {
    english.push(arr.name.en);
    german.push(arr.name.de);
  });
  return { en: english, de: german };
};

export const getId = (mainList, list, language) => {
  let result = [];
  if (list) {
    list.forEach(m => {
      mainList.forEach(ml => {
        if (ml.name[`${language}`] === m) {
          result.push(ml.id);
        }
      });
    });
  } else {
    mainList.forEach(ml => {
      result.push(ml.id);
    });
  }
  return result;
};

export const dDigit = number => {
  return (number = ("0" + number).slice(-2));
};

export const extractIdCounty = (list, mainList) => {
  let r = [];

  if (list) {
    mainList.forEach(main => {
      list.forEach(l => {
        if (l === main.name) {
          r.push(main.id);
        }
      });
    });
  } else {
    mainList.forEach((ml, index) => {
      if (ml.id !== 0) r.push(ml.id);
    });
  }
  return r;
};

export const extractIdForName = (list, mainList) => {
  let r = [];

  if (list) {
    list.map(name => {
      return mainList.forEach(main => {
        if (main.id) {
          if (name === main.name) {
            r.push(main.id);
          }
        } else if (main.value) {
          if (name === main.name) {
            r.push(main.value);
          }
        }
      });
    });
  } else {
    mainList.forEach((m, index) => {
      if (typeof m.id !== "undefined" && m.id !== 0) {
        r.push(m.id);
      }
    });
  }

  return r;
};

export const renameKeys = (keysMap, mainObj) => {
  return mainObj.map(obj => {
    return Object.keys(obj).reduce((acc, key) => {
      const renamedObject = {
        [keysMap[key] || key]: obj[key]
      };

      return {
        ...acc,
        ...renamedObject
      };
    }, {});
  });
};

export const findId = (list, mainList) => {
  var r = [];
  if (list) {
    list.forEach(l => {
      mainList.forEach(mL => {
        if (l === mL.name) {
          r.push(mL.id);
        }
      });
    });
  }
  return r;
};
