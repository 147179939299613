import client from "./index";
import { routes } from "./../_api/routes";
import { ERROR, GET_CREDITOR_DETAIL, LOADING, LOADING_DONE } from "./types";
import {extractServerErrors} from "../_utils/validate";

export const getCreditor = (id, callback) => async dispatch => {
  dispatch({
    type: LOADING,
    payload: {
      URL: `${routes.getCreditorDetail}/${id}`,
      method: "get",
      data: id
    }
  });
  try {
    const response = await client.get(`${routes.getCreditorDetail}/${id}`);

    dispatch({
      type: GET_CREDITOR_DETAIL,
      payload: response.data.data
    });
    callback();
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
            typeof e.response === "undefined"
                ? e.message
                : extractServerErrors(e.response.data)
      });
    }
  }finally {
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.getCreditorDetail}/${id}`,
        method: "get",
        data: id
      }
    });
  }
};

export const creditorDetail = (pId, aId, callback) => async dispatch => {
  dispatch({
    type: LOADING,
    payload: {
      URL: `${routes.creditorDetail}/${pId}/applications/${aId}`,
      method: "get",
      data: pId,
      aId
    }
  });
  try {
    const response = await client.get(
      `${routes.creditorDetail}/${pId}/applications/${aId}`
    );

    dispatch({
      type: GET_CREDITOR_DETAIL,
      payload: response.data.data
    });
    callback();
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
            typeof e.response === "undefined"
                ? e.message
                : extractServerErrors(e.response.data)
      });
    }

  }finally {
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.creditorDetail}/${pId}/applications/${aId}`,
        method: "get",
        data: pId,
        aId
      }
    });
  }
};
