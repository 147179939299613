import React, { Fragment, Component } from "react";
import { textAreaField, ReactUploader } from "../../_formFields/index";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  sendOfferRequest,
  getApplicationDetail
} from "../../actions/application";
import { changeStatus } from "../../actions/changeStatus";
import Translate from "react-translate-component";
import Loading from "../general/Loading";
class SendOffer extends Component {
  state = { detail: null, refresh: false };
  componentDidMount() {
    const { productId, applicationId } = this.props.location.state;
    this.props.getApplicationDetail(productId, applicationId);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.data !== prevProps.data) {
      this.setState({ detail: this.props.data.detail });
    }
    if (this.state.refresh !== prevState.refresh) {
      const { productId, applicationId } = this.props.location.state;
      this.props.getApplicationDetail(productId, applicationId);
    }
  }

  onSubmit = formProps => {
    const { productId, applicationId } = this.props.location.state;
    const status = "accepted";
    this.props.changeStatus(productId, applicationId, status, () =>
      this.setState({ refresh: !this.state.refresh })
    );
    this.props.sendOfferRequest(productId, applicationId, formProps, () => {
      this.props.history.push("/credit-request");
    });
  };
  render() {
    const { handleSubmit } = this.props;

    return (
      <Fragment>
        <div className="content-head">
          <div className="content-head-left">
            <Translate
              content="button.sendOffer"
              component="h1"
              className="content-head__title"
            />
          </div>
        </div>
        <div className="content-body">
          <Loading />
          <form
            className="form-send0ffer"
            onSubmit={handleSubmit(this.onSubmit)}
          >
            <div className="form-group">
              <Translate
                content="label.offerLetter"
                component="label"
                htmlFor="offer-letter"
              />
              <Field
                name="offerLetter"
                type="textarea"
                className="form-control"
                component={textAreaField}
                cols="20"
                rows="5"
              />
            </div>
            <div className="form-group">
              <Translate content="label.agreement" component="label" />
              <Field
                component={ReactUploader}
                className="file-uploader file-uploader--small dropzone "
                type="file"
                name="files"
              />
            </div>
            <div className="row mt-4">
              <Translate
                content="button.sendOffer"
                component="button"
                className="btn btn-primary btn-form"
                type="submit"
              />
            </div>
            {this.props.errMsg ? (
              <li className="d-flex mb-1">
                <span className="pl-2 green-text">{this.props.errMsg}</span>
              </li>
            ) : null}
          </form>
        </div>
      </Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    errMsg: state.error,
    data: state.applicationDetail
  };
}

export default compose(
  connect(mapStateToProps, {
    sendOfferRequest,
    changeStatus,
    getApplicationDetail
  }),
  reduxForm({
    form: "sendOffer"
  })
)(SendOffer);
