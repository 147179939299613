export default {
  "alert": {
    "product_add": "Ihre Pruduktanfrage  ist eingegangen. Wir bearbeiten Ihren Antrag und Sie werden schon in Kürze eine Rückmeldung erhalten.",
    "product_edit": "Produkt ist bearbeitet.",
    "product_delete": "Produkt wird gelöscht.",
    "product_postpone": "Produkt wird verschoben.",
    "product_postpone_undo": "Produktverschiebung wird rückgängig gemacht."
  },
  "button": {
    "Produktbearbeiten": "Produkt bearbeiten",
    "signup": "Jetzt registrieren",
    "search": "Suche",
    "addnewproduct": "Kreditprodukt hinzufügen",
    "submit": "Absenden",
    "edit": "Produkt bearbeiten",
    "download": "Herunterladen",
    "delete": "Löschen",
    "postpone": "Aussetzen",
    "undopostpone": "Aussetzen rückgängig machen",
    "sendOffer": "Angebot senden",
    "downloadAll": "alle herunterladen",
    "moreDetail": "Mehr Details",
    "send": "Senden"
  },
  "column": {
    "name": "Name",
    "category": "Kategorie",
    "interest": "Zinsen",
    "minimum_credit_amount": "Mindestkreditbetrag",
    "available_credit_amount": "Kreditbetrag",
    "status": "Status",
    "edit": "Bearbeiten",
    "minimum_balance": "Höchstkreditbetrag",
    "duration": "Laufzeit",
    "services": "Kreditart",
    "creditorsname": "Kreditnehmer",
    "industry": "Branche",
    "investedon": "Bestätigt am",
    "investedamount": "Kreditbetrag",
    "numberofrequest": "Anzahl der Anfragen",
    "createdon": "Erstellt",
    "productname": "Produktname",
    "requeston": "Anfrage am",
    "requestedby": "Angefragt von",
    "requestedamount": "Kreditbetrag",
    "credittype": "Kreditprodukt",
    "yourejected": "Sie haben diese Investition abgelehnt",
    "iagree": "Hiermit stimme ich den ",
    "terms": "Geschäftsbedingungen",
    "terms_ending": "",
    "addfile": "Dokument auswählen oder Dokument ablegen",
    "approvedon": "Bestätigt am",
    "noattachment": "Keine Anhänge verfügbar",
    "appreject": "Sie haben diesen Antrag abgelehnt.",
    "appaccept": "Sie haben diesen Antrag angenommen.",
    "norating": "Keine Bewertung vorhanden.",
    "minduration": "Mindestlaufzeit",
    "maxduration": "Höchstlaufzeit"
  },
  "error": {
    "notification": "Please refresh the page or contact to the support"
  },
  "label": {
    "Produktdetail": "Produktdetails",
    "processing": "In Bearbeitung",
    "AngebotErstellt": "Angebot erstellt",
    "theperfect": "Finden Sie Ihren neuen Firmenkredit",
    "ZuBearbeiten": "Zu bearbeiten",
    "Kreditorname": "Kreditorname",
    "atcredittech": "Melden Sie sich als Kreditgeber an und Sie finden in wenigen Schritten neue Kreditkunden.\n Alle Branchen - Regional - Überregional!",
    "whycredittech": "Warum piHub?",
    "atfirst": "Zuerst habe ich wenig investiert und mit der Zeit habe ich in den Sektor investiert, den ich mag, und Credit Tech bringt mich genau dorthin.",
    "whatothers": "Was sagen andere?",
    "signupto": "Melden Sie sich als Kreditgeber bei piHub an:",
    "enteryourdetails": "Geben Sie bitte unten Ihre Daten ein.",
    "firstname": "Vorname",
    "lastname": "Nachname",
    "companyname": "Name der Firma",
    "emailaddress": "E-Mail-Adresse",
    "password": "Passwort",
    "confirmpassword": "Passwort bestätigen",
    "phonenumber": "Telefonnummer",
    "alreadyhaveanaccount": "Sie haben schon ein Konto?",
    "login": "Anmelden",
    "banner_desc": "Melden Sie sich als Kreditgeber an und Sie finden in wenigen Schritten neue Kreditkunden. Alle Branchen - Regional - Überregional",
    "ifyoudont": "Wenn Sie noch kein Konto haben, können Sie sich",
    "youdont": "Sie haben keine (neuen) Benachrichtigungen.",
    "enteryouremail": "Geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein.",
    "here": "hier registrieren.",
    "sendit": "Bestätigungslink erneut senden.",
    "didnt": "Sie haben keine E-Mail erhalten?",
    "youraccounthas": "Ihr Account wurde erfolgreich erstellt. Bitte überprüfen Sie Ihr E-Mail-Postfach, dort finden Sie einen Bestätigungslink.",
    "weve": "Wir haben Ihnen eine E-Mail gesendet!",
    "activation": "Aktivierung",
    "adminapproval": "Administrator-Genehmigung",
    "confirmemail": "Bestätigungs-E-Mail",
    "theconfirm": "Dieser Bestätigungslink ist abgelaufen.",
    "wecant": "Wir können Ihre E-Mail-Adresse nicht bestätigen.",
    "ouradminneed": "Sobald Ihr Account freigeschaltet ist, werden wir Sie informieren.",
    "justonemore": "Jetzt fehlt nur noch die Freigabe durch den Administrator!",
    "youcanask": "Hier können Sie einen neuen Validierungslink anfordern.",
    "youraccounthasbeen": "Ihr Account wurde freigeschaltet. Sie können Ab sofort alle piHub-Funktionen nutzen.",
    "wow": "Herzlich Willkommen bei Pihub!",
    "attachments": "Anlagen",
    "timeduration": "Laufzeit (in Monaten)",
    "investor": "Investor",
    "maxcredit": "Höchstkreditbetrag",
    "industries": "Branche",
    "state": "Bundesland",
    "service": "Kreditart",
    "Sicherheiten": "Sicherheiten",
    "Ratingagentur": "Ratingagentur",
    "producttitle": "Produktname",
    "requested": "Angefordert",
    "approved": "Genehmigt",
    "rejected": "Abgelehnt",
    "invested": "Investiert",
    "allproducts": "Alle Kreditprodukte",
    "fileupload": "Dokumente hochladen",
    "no": "Nein",
    "yes": "Ja",
    "Kreditrating": "Kreditrating",
    "county": "Landkreis",
    "editproducts": "Produkte bearbeiten",
    "mincredit": "Mindestkreditbetrag",
    "notifications": "Benachrichtigungen",
    "pagenot": ":( 404 Error - Seite nicht gefunden.",
    "thepage": "Die von Ihnen gesuchte Seite wurde möglicherweise entfernt. Ein Grund dafür kann sein, dass sich Ihr Name geändert hat oder die Seite ist vorübergehend nicht verfügbar.",
    "back": "Zurück zur Startseite",
    "logout": "Logout",
    "accept": "Akzeptieren",
    "requestedamount": "Kreditbetrag",
    "time": "Zeitdauer",
    "deadline": "Fristablauf (Angebot)",
    "applicationcode": "Anwendungscode",
    "creditrequests": "Kreditanträge",
    "youdonot": "Sie haben noch keine Kreditprodukte angelegt.",
    "profile": "Profil",
    "minimumsales": "Mindestjahresumsatz Kreditnehmer",
    "resetPassword": " Passwort ändern",
    "months": "Monate",
    "open": "Öffnen",
    "postponed": "Ausgesetzt",
    "deleted": "Gelöscht",
    "deletedmsg": "Dieses Produkt wurde gelöscht.",
    "postponedmsg": "Dieses Produkt wurde ausgesetzt. Sie können das Produkt auf dieser Seite wieder aktivieren.",
    "suspended": "Pausiert",
    "reject": "Ablehnen",
    "forgotPassword": "Passwort vergessen?",
    "enterEmailForgotten": "Geben Sie Ihre E-Mail-Adresse ein.",
    "status": "Status",
    "ratingForCredit": "Kreditrating",
    "nda": "Vertraulichkeitserklärung",
    "deadlineForPayment": "Gewünschter Auszahlungstermin",
    "deadine": "Gewünschte Angebotsfrist",
    "collaterals": "Art der Sicherheit",
    "salesAmount": "Jahresumsatz (letztes Geschäftsjahr)",
    "reasons": "Verwendungszweck",
    "investorAttachments": "Investor Anlagen",
    "otherContactPerson": "Weitere Ansprechpartner",
    "headquarter": "Hauptsitz",
    "streetAddress": "Straße und Hausnummer",
    "postalCode": "Postleitzahl",
    "contactPerson": "Ansprechpartner",
    "socialMedia": "Soziale Medien",
    "investorCategory": "Kategorie",
    "editProfile": "Profil ändern",
    "update": "ändern",
    "accountNotVerified": "Konto noch nicht verifiziert",
    "accountNotConfirmed": "Konto noch nicht bestätigt",
    "contact": "Kontakt",
    "loginTitle": "In wenigen Schritten zu Ihrem neuen Kreditnehmer",
    "loginDescription": "Individuell | Alle Branchen | Alle Kreditprodukte | Regional | Überregional | ab 1.000.000 EUR",
    "confirmationLink": "Bestätigungslink",
    "passwordChangedSuccessful": "Erfolgreiche Passwortänderung",
    "youCanUseYourNewPassword": "Mit Ihrem neuen Passwort können Sie sich bei Ihrem creditech-Konto anmelden.",
    "withYourNewPassword": "mit deinem neuen Passwort",
    "weJustSent": "Wir haben dir gerade eine E-Mail geschickt.",
    "anEmailWithInstruction": "Eine E-Mail mit einer Anleitung zum Zurücksetzen Ihrer E-Mail wurde an folgende Adresse gesendet",
    "ratingaggentur": "Ratingaggentur",
    "kreditrating": "Kreditrating",
    "resetYourPassword": " Passwort ändern",
    "enterAnEmailAssociated": "Gib die mit deinem Account verknüpfte Mail Adresse ein.",
    "changePassword": "Du möchtest dein Passwort ändern?",
    "all": "Alle",
    "canceled": "Abgebrochen",
    "expired": "Ausgelaufen",
    "agreement": "Vertrag",
    "offerLetter": "Angebot",
    "makeAnOffer": "Angebot machen",
    "weCantseem": "Die Seite die du aufrufen möchtest ist nicht verfügbar",
    "takeMeHome": "Zurück zur Startseite",
    "minNetProfit": "Minimaler Nettogewinn",
    "onReview": "zur Überprüfung",
    "pleaseComplete": "Bitte füllen Sie Ihr Profil aus, bevor Sie das Formular ausfüllen."
  },
  "placeholder": {
    "select": "Auswählen",
    "and": "zu und erkenne die ",
    "privacy_policy_start": "Unsere ",
    "privacy_policy": "Datenschutzhinweise",
    "privacy_policy_ending": " finden Sie hier.",
    "noActiveProduct": "Sie haben noch keine aktiven Kredite.",
    "noCreditRequests": "Dieses Produkt hat keine Kreditanfrage.",
    "justASecond": "Einen Moment bitte...",
    "selectAllStates": "deutschlandweit",
    "selectAllCounties": "Alle Landkreis auswählen",
    "selectAll": "Alle auswählen",
    "notAvailable": "Nicht verfügbar.",
    "changeProfilePic": "Firmenbild ändern",
    "verified": "Bestätigt",
    "unverified": "Nicht bestätigt",
    "enterYourWebsite": "Gib deine URL ein",
    "companyName": "Firmenname",
    "headquarterLocation": "Firmensitz",
    "contactName": "Name des Ansprechpartners",
    "contactName2": "Name des Ansprechpartners-2",
    "contactName3": "Name des Ansprechpartners-3",
    "contactEmail": "Mail Adresse des Ansprechpartners",
    "contactEmail2": "Mail Adresse des Ansprechpartners-2",
    "contactEmail3": "Mail Adresse des Ansprechpartners-3",
    "contactPhoneNo": "Telefonnummer des Ansprechpartners",
    "contactPhoneNo2": "Telefonnummer des Ansprechpartners-2",
    "contactPhoneNo3": "Telefonnummer des Ansprechpartners-3",
    "facebookURL": "Facebook URL",
    "twitterURL": "Twitter URL",
    "LinkedinURL": "Linkedin URL",
    "xingURL": "Xing URL",
    "NewPassword": "Neues Passwort",
    "Retypepassword": "Gib dein Passwort erneut ein"
  },
  "sidebar": {
    "product": "Kreditprodukte",
    "new_product": "Kreditprodukte anlegen",
    "invested_products": "Aktive Kredite",
    "products": "Kreditprodukte",
    "credit_requested_products": "Kreditanträge"
  },
  "statuses": {
    "offer": {
      "open": "Geöffnet",
      "on_review": "auf Überprüfung",
      "accepted_by_investor": "Angebot erstellt",
      "offer_rejected": "Angebot abgelehnt",
      "rejected_by_creditor": "Abgelehnt",
      "accepted_by_creditor": "Akzeptiert"
    },
    "product": {}
  },
  "tooltip": {
    "productName": "Falls das Kreditprodukt, das Sie anbieten möchten, einen Namen hat, können Sie diesen hier nennen. So können Sie das Produkt besser finden und wiedererkennen.",
    "documents": "Hier können Sie weitere Informationen zu Ihrem Kreditprodukt in Form eines oder mehrerer Dokumente hochladen. Dies ist kein Pflichtfeld.",
    "rating": "Bitte geben Sie – falls notwendig – das Mindestrating ein, das ein Unternehmen haben muß, damit Ihr Haus einen Kredit gewähren kann.",
    "collateral": "Geben Sie bitte an, ob Unternehmen an die Sie Kredite vergeben möchten über Sicherheiten verfügen müssen.",
    "minSales": "Bitte geben Sie die Mindestumsatzgröße der Unternehmen ein, an die Sie Kredite vergeben möchten.",
    "maxCreditAmount": "Bitte geben Sie die Maximalgröße für eine Kreditanfrage ein.",
    "minCreditAmount": "Bitte geben Sie die Mindestgröße für eine Kreditanfrage ein.",
    "timeDuration": "Sie können hier die Mindest- und die Maximallaufzeit angeben.",
    "industry": "Sie können hier angeben, wenn Sie Kredite nur an bestimmte Branchenvergeben. Mehrfachnennungen sind möglich. Alternativ können Sie auch einfach „alle“ angeben.",
    "county": "Bitte wählen Sie mindestens einen Landkreis aus.",
    "service": "Bitte nennen Sie die Kreditart, welche Sie hier anbieten möchten.",
    "states": "Wählen Sie bitte hier aus für welche Bundesländer Sie Kredite anbieten möchten.",
    "phone_number": "Bitte geben Sie Ihre Kontaktnummer ein und wählen Sie Ihr Land aus",
    "password": "Passwort muß mindestens einen Großbuchstaben, ein Sonderzeichen und eine Zahl haben",
    "email_address": "Geben Sie Ihre E-Mail-Adresse ein",
    "company_name": "Name des Unternehmens Bitte geben Sie hier den Namen Ihres Unternehmens an. Wir bearbeiten keine Anfragen von Privatpersonen",
    "last_name": "Bitte geben Sie Ihren Familiennamen ein",
    "first_name": "Bitte geben Sie Ihren Vornamen ein",
    "headquarter": "Bitte geben Sie den Staat an, in dem Ihr Unternehmen ansässig ist.",
    "street_address": "Bitte geben Sie die Straßenadresse Ihres Unternehmens ein. ",
    "postal_code": "Bitte geben Sie die Postleitzahl Ihres Unternehmens ein",
    "contact_person": "Bitte geben Sie den Namen, die E-Mail-Adresse und die Kontaktnummer der Person ein",
    "contact_person_2": "Bitte geben Sie den Namen, die E-Mail-Adresse und die Kontaktnummer der zweiten Person ein",
    "contact_person_3": "Bitte geben Sie den Namen, die E-Mail-Adresse und die Kontaktnummer der dritten Person ein.",
    "social_media": "Bitte URL für Social Media eingeben",
    "file": "Laden Sie alle anderen wichtigen Dateien hoch, wenn Sie welche haben.",
    "investor_category": "Bitte wählen Sie die Kategorie aus der Liste unten aus.",
    "min_net_profit": "Mindest-Nettogewinn von 0 bis 5 Millionen"
  },
  "unconfirmed": {
    "msg": "Wir haben Ihnen eine E-Mail gesendet! Bitte überprüfen Sie Ihr E-Mail-Postfach, dort finden Sie einen Bestätigungslink.",
    "emailSent": "Wir haben Ihnen eine E-Mail gesendet!"
  },
  "unverified": {
    "msg": "Ihr Konto wurde noch nicht durch den Administrator freigeschaltet. Sobald dies geschehen ist, erhalten Sie eine E-Mail und können dann alle piHub-Funktionen nutzen."
  },
  "validation": {
    "required": "* Pflichtfeld",
    "email": "Ungültige E-Mail-Adresse",
    "phoneNumber": "Ungültige Telefonnummer, muss 10-stellig sein",
    "number": "* Muss eine Nummer sein",
    "same": "Ihre Passworteingaben stimmen nicht überein.",
    "password": "Das Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, ein Sonderzeichen (@, #, $) und einen Zahlenwert enthalten und aus 8 Ziffern bestehen",
    "newEmail": "Ungültige E-Mail!",
    "pleaseAcceptTermsAndCondition": "Bitte stimmen Sie den Geschäftsbedingungen zu.",
    "fillAllRequiredFields": "Bitte füllen Sie alle Pflichtfelder aus"
  }
};
