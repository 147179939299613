import React, {Component, Fragment} from 'react';
import Translate from 'react-translate-component';
import {Link} from 'react-router-dom';
import {confirmationLink} from '../../actions/confirmationLink';
import {connect} from 'react-redux';
import {getProfile} from '../../actions/profile';
import {showFlashMessage, hideFlashMessage} from "../../actions/flashMessage";

class UnconfirmedPage extends Component {

    componentDidMount() {
        document.title = "Email Not Confirmed"
        this.props.getProfile();
    }

    onClick = () => {
        if (this.props.profile && this.props.profile.email && this.props.profile.email.length > 0)
            this.props.confirmationLink(this.props.profile.email, () => {
                this.props.showFlashMessage('unconfirmed.emailSent');
                setTimeout(
                    () => {
                        this.props.hideFlashMessage();
                    },
                    5000
                );
            });
    }

    render() {
        return (
            <Fragment>
                <div className="content m-auto">
                    <div className="email-content text-center w-75 m-auto"><img
                        src="/assets/img/icons/not-verified.svg"
                        alt="Mail icon"/>
                        <Translate content='label.accountNotConfirmed' component="h3"/>
                        <Translate content='unconfirmed.msg' component="p"/>
                        <Translate content='label.didnt'/> &nbsp;
                        <Link to="#" onClick={this.onClick}><Translate content='label.sendit'/></Link>
                    </div>
                </div>
            </Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {profile: state.profile};
}

export default connect(
    mapStateToProps, {confirmationLink, getProfile, showFlashMessage, hideFlashMessage})(UnconfirmedPage);
