import React from "react";
import { Link, withRouter } from "react-router-dom";
import Translate from "react-translate-component";
import { SidebarContext } from "./SidebarContext";

class Sidebar extends React.Component {
  static contextType = SidebarContext;
  static subMenu = {
    collapseInvestor: [
      "/products",
      "/credit-request",
      "/add-product",
      "/products-invested"
    ]
  };

  render() {
    return (
      <div className={`sidebar ${this.context.sidebar}`}>
        <div className={`hamburger ${this.context.sidebar}`}>
          <div
            className="hamburger-bar--close"
            onClick={this.context.toggleSidebar}
          ></div>
        </div>
        <nav className="nav-sidebar">
          <ul className="menu-sidebar">
            <li
              className={`sub-menu ${
                Sidebar.subMenu.collapseInvestor.includes(
                  this.props.location.pathname
                )
                  ? "show"
                  : ""
              }`}
            >
              <Link
                to="/products"
                id="dropdown-menu"
                data-toggle="collapse"
                data-target="#collapseInvestor"
                aria-expanded="false"
                aria-controls="collapseInvestor"
              >
                <i className="bx bx-dollar-circle" />
                <Translate content="sidebar.product" />
              </Link>
              <ul
                id="collapseInvestor"
                className="sub-menu collapse show"
                aria-labelledby="headingOne"
              >
                <li
                  className={
                    this.props.location.pathname === "/products"
                      ? "current-menu"
                      : null
                  }
                >
                  <Link to="/products">
                    <Translate content="sidebar.products" />
                  </Link>
                </li>
                <li
                  className={
                    this.props.location.pathname === "/credit-request"
                      ? "current-menu"
                      : null
                  }
                >
                  <Link to="/credit-request">
                    <Translate content="sidebar.credit_requested_products" />
                  </Link>
                </li>

                <li
                  className={
                    this.props.location.pathname === "/products-invested"
                      ? "current-menu"
                      : null
                  }
                >
                  <Link
                    to="/products-invested"
                    target="_self"
                    rel="noopener noreferrer"
                  >
                    <Translate content="sidebar.invested_products" />
                  </Link>
                </li>
                <li
                  className={
                    this.props.location.pathname === "/add-product"
                      ? "current-menu"
                      : null
                  }
                >
                  <Link to="/add-product">
                    <Translate content="sidebar.new_product" />
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default withRouter(Sidebar);
