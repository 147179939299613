import React, { Component } from "react";
import Sidebar from "./general/Sidebar";
import Header from "./general/Header";
import FlashMessage from "./general/FlashMessage";
import { logoutAllTabsEventListner } from "../actions/login";
import { connect } from "react-redux";
import { SidebarContext } from "./general/SidebarContext";
export default ChildComponent => {
  class App extends Component {
    componentDidMount() {
      this.props.logoutAllTabsEventListner();
    }

    constructor(props) {
      super(props);

      this.toggleSidebar = () => {
        this.setState(state => ({
          sidebar: state.sidebar === "expanded" ? "" : "expanded"
        }));
      };

      this.state = {
        sidebar: "",
        toggleSidebar: this.toggleSidebar
      };
    }

    render() {
      return (
        <div className="container-full-height ct-container">
          <SidebarContext.Provider value={this.state}>
            <Sidebar />
          </SidebarContext.Provider>
          <div className="main-content main-content--padded">
            <SidebarContext.Provider value={this.state}>
              <Header />
            </SidebarContext.Provider>
            <FlashMessage />
            <ChildComponent {...this.props} />
          </div>
        </div>
      );
    }
  }
  return connect(null, { logoutAllTabsEventListner })(App);
};
