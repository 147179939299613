import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import { Multiselect} from "react-widgets/cjs";
import Dropzone from "react-dropzone";
import Translate from "react-translate-component";
import InputRange from "react-input-range";
import ReactPhoneInput from "react-phone-input-2";
import InputFieldError from "../error/InputFieldError";
import RDropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import "react-phone-input-2/lib/style.css";
import "react-widgets/styles.css";

export const inputField = ({
  input,
  label,
  type,
  className,
  id,
  placeholder,
  meta: { error, touched }
}) => {
  return (
    <div>
      {label ? (
        <label>
          <strong>{label}</strong>
        </label>
      ) : null}
      <Translate
        {...input}
        type={type}
        color={"white"}
        className={className}
        id={id}
        component="input"
        attributes={{ placeholder: placeholder }}
      />
      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </div>
  );
};

export const textAreaField = ({
  input,
  label,
  type,
  className,
  id,
  placeholder,
  readOnly,
  cols,
  rows,
  meta: { error, touched }
}) => {
  return (
    <div>
      <label>{label}</label>
      <textarea
        {...input}
        type={type}
        color={"white"}
        className={className}
        id={id}
        placeholder={placeholder}
        cols={cols}
        rows={rows}
        readOnly={readOnly}
      />
      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </div>
  );
};

export const checkBox = ({
  input,
  label,
  type,
  className,
  id,
  placeholder,
  meta: { error, touched }
}) => {
  return (
    <div className="form-check">
      <input
        {...input}
        type={type}
        color={"white"}
        className={className}
        id={id}
        placeholder={placeholder}
      />
      &nbsp;
      <label className="form-check-label">{label}</label>
      <p>
        <InputFieldError
          touched={touched}
          error={<Translate content={error} />}
        />
      </p>
    </div>
  );
};

export const dropDownField = ({
  options,
  input,
  label,
  type,
  className,
  id,
  placeholder,
  defaultValue,
  meta: { error, touched }
}) => {
  return (
    <div className="form-group">
      {label ? (
        <label>
          <strong>{label}</strong>
        </label>
      ) : null}
      <Select
        {...input}
        onChange={value => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        options={options}
        className={className}
        placeholder={placeholder}
        attributes={{ placeholder: "placeholder.select" }}
      />
      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </div>
  );
};

export const inputSlider = ({
  defaultValue,
  step,
  input,
  label,
  type,
  className,
  id,
  placeholder,
  max,
  min,
  meta: { error, touched }
}) => {
  return (
    <Fragment>
      <label>{label}</label>

      <input
        {...input}
        className="range-slider"
        id={id}
        placeholder={placeholder}
        type={type}
        min={min}
        max={max}
        step={step}
        value={defaultValue}
        data-orientation="horizontal"
      />

      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </Fragment>
  );
};

export const renderMultiselect = ({
  input,
  label,
  type,
  className,
  id,
  placeholder,
  meta: { error, touched },
  data,
  valueField,
  textField,
  defaultValue
}) => {
  return (
    <Fragment>
      <label htmlFor="">
        <strong>{label}</strong>
      </label>
      <Multiselect
        {...input}
        onBlur={() => input.onBlur()}
        value={
          input.value[0] === "Select All" || input.value[0] === "Alle auswählen"
            ? data
            : input.value || []
        }
        data={data}
        valueField={valueField}
        textField={textField}
        className={className}
        id={id}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </Fragment>
  );
};

export const renderDropzoneField = ({
  input,
  file,
  name,
  id,
  meta: { touched, error }
}) => {
  return (
    <Fragment>
      <Dropzone
        onDrop={acceptedFiles => {
          input.onChange(acceptedFiles);
        }}
        maxSize={8000000}
        multiple={true}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className="file-uploader file-uploader--small dropzone"
          >
            <div className="dz-message">
              <img src="/assets/img/icons/bx-cloud-upload.png" alt="" />
              <p>
                <Translate
                  content="column.addfile"
                  component="a"
                  className="font-weight-bold mr-1"
                />
                <Translate content="column.ordrop" />
              </p>
              <div className="fallback" />
            </div>
            <input {...getInputProps()} />
          </div>
        )}
      </Dropzone>
      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </Fragment>
  );
};

export const radioButton = ({
  input,
  label,
  type,
  className,
  id,
  placeholder,
  meta: { error, touched }
}) => {
  return (
    <div>
      <label>
        <strong>{label}</strong>
      </label>
      <input
        {...input}
        type="radio"
        color={"white"}
        className={className}
        id={id}
        placeholder={placeholder}
      />
      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </div>
  );
};

export const inputDoubleSlider = ({
  input,
  label,
  type,
  className,
  id,
  placeholder,
  max,
  min,
  meta: { error, touched }
}) => {
  return (
    <Fragment>
      <label>{label}</label>

      <div className="demo col-md-9 col-sm-8 col-8">
        <InputRange
          maxValue={max}
          minValue={min}
          value={{ max: max, min: min }}
          onChange={value => {}}
        />
      </div>

      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </Fragment>
  );
};

// export const renderDatePicker = ({
//                                      label,
//                                      selectedValue,
//                                      input: {onChange, value},
//                                      onCurrentDateChange,
//                                      showTime,
//                                      required,
//                                      minDate,
//                                      currentDate,
//                                      disabled,
//                                      meta: {touched, error, warning}
//                                  }) => (
//     <div>
//         {/* <label>{label}</label> */}
//         <div>
//             <DateTimePicker
//                 onChange={onChange}
//                 format="DD.MM.YYYY"
//                 value={
//                     !value ? (selectedValue ? selectedValue : null) : new Date(value)
//                 }
//                 time={showTime}
//                 min={minDate ? minDate : new Date()}
//                 required={required}
//                 defaultCurrentDate={currentDate}
//                 disabled={disabled}
//                 // onCurrentDateChange={onCurrentDateChange}
//             />
//             <InputFieldError
//                 touched={touched}
//                 error={<Translate content={error}/>}
//             />
//         </div>
//     </div>
// );

export const renderPhone = ({
  label,
  input: { onChange, value },
  inputExtraProps: { name, required },
  // name,
  regions,
  placeholder,
  country,
  meta: { touched, error }
}) => (
  <div>
    <label>{label}</label>
    <Fragment>
      <ReactPhoneInput
        inputExtraProps={{
          name: name,
          required: required
        }}
        onChange={onChange}
        value={value}
        regions={regions}
        placeholder={placeholder}
        country={country}
      />
      <InputFieldError
        touched={touched}
        error={<Translate content={error} />}
      />
    </Fragment>
  </div>
);

export const ReactUploader = ({ input }) => {
  let uploadedFiles = [];
  const handleChangeStatus = files => {
    let alreadyExists = false;
    uploadedFiles.forEach(uploadedFile => {
      if (uploadedFile.name === files.file.name) {
        alreadyExists = true;
      }
    });
    if (!alreadyExists) {
      uploadedFiles.push(files.file);
    }
    input.onChange(uploadedFiles);
  };

  const Layout = ({
    input,
    previews,
    submitButton,
    dropzoneProps,
    files,
    extra: { maxFiles }
  }) => {
    return (
      <div>
        <div {...dropzoneProps}>
          <div className="dropzone-preview">{previews}</div>
          {files.length < maxFiles && input}
        </div>
      </div>
    );
  };

  const CustomInput = ({ accept, files, onFiles, getFilesFromEvent }) => {
    const text =
      files.length > 0 ? (
        <Translate content="column.addfile" />
      ) : (
        <Translate content="column.addfile" />
      );

    return (
      <label className="fileupload-label">
        <div className="fileupload-label-content">
          <img src="/assets/img/icons/bx-cloud-upload.png" alt="" />
          {text}
        </div>

        <input
          style={{ display: "none" }}
          type="file"
          accept={accept}
          multiple
          onChange={e => {
            getFilesFromEvent(e).then(chosenFiles => {
              onFiles(chosenFiles);
            });
          }}
        />
      </label>
    );
  };

  const getFilesFromEvent = e => {
    return new Promise(resolve => {
      getDroppedOrSelectedFiles(e).then(chosenFiles => {
        resolve(chosenFiles.map(f => f.fileObject));
      });
    });
  };

  return (
    <RDropzone
      onChangeStatus={handleChangeStatus}
      autoUpload={false}
      //   PreviewComponent={Preview}
      LayoutComponent={Layout}
      InputComponent={CustomInput}
      getFilesFromEvent={getFilesFromEvent}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
        </div>
      )}
    </RDropzone>
  );
};

export const checkBoxTermsConditions = ({
  input,
  label,
  type,
  className,
  id,
  placeholder,
  meta: { error, touched }
}) => {
  return (
    <div className="form-check">
      <input
        {...input}
        type={type}
        color={"white"}
        className={"form-check-input"}
        id={id}
        placeholder={placeholder}
      />
      <label className="form-check-label" style={{ fontWeight: 100 }}>
        <Translate content="column.iagree" />
        <Link to="/terms-and-conditions" target="_blank">
          <Translate content="column.terms" style={{ fontWeight: 600 }} />
        </Link>
        <br />
        <Translate content="placeholder.privacy_policy_start" />
        <Link to="/privacy-policy" target="_blank">
          <Translate
            content="placeholder.privacy_policy"
            style={{ fontWeight: 600 }}
          />
        </Link>
        <Translate content="placeholder.privacy_policy_ending" />
        <p>
          <InputFieldError
            touched={touched}
            error={<Translate content={error} />}
          />
        </p>
      </label>
    </div>
  );
};
