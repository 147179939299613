export default {
  "alert": {
    "product_add": "Your product request has been received. We will process your request and you will receive feedback shortly.",
    "product_edit": "product is edited.",
    "product_delete": "product is deleted.",
    "product_postpone": "product is postponed.",
    "product_postpone_undo": "product postpone is undone."
  },
  "button": {
    "Produktbearbeiten": "Edit Product",
    "signup": "Sign up",
    "search": "Search",
    "addnewproduct": "Add new products",
    "submit": "Submit",
    "edit": "Edit Product",
    "download": "Download",
    "delete": "Delete",
    "postpone": "Postpone",
    "undopostpone": "Undo postpone",
    "sendOffer": "Send Offer",
    "downloadAll": "Download All",
    "moreDetail": "More Detail",
    "send": "Send"
  },
  "column": {
    "name": "Name",
    "category": "Category",
    "interest": "Interest",
    "minimum_credit_amount": "Minimum Amount",
    "available_credit_amount": "Available Amount",
    "status": "Status",
    "edit": "Edit",
    "minimum_balance": "Minimum Balance",
    "duration": "Duration",
    "services": "Services",
    "creditorsname": "Creditors Name",
    "industry": "Industry",
    "investedon": "Invested on",
    "investedamount": "Invested amount",
    "numberofrequest": "Number of request",
    "createdon": "Created on",
    "productname": "Product name",
    "requeston": "Request on",
    "requestedby": "Requested by",
    "requestedamount": "Requested amount",
    "credittype": "Credit type",
    "yourejected": "You rejected this Investment",
    "iagree": "I agree to the ",
    "terms": " terms and conditions.",
    "terms_ending": "",
    "addfile": "Add files or drop files here",
    "approvedon": "Approved on",
    "noattachment": "No Attachments available",
    "appreject": "You have rejected this application",
    "appaccept": "You have accepted this application",
    "norating": "No ratings available",
    "minduration": "Minimum Duration",
    "maxduration": "Maximum Duration"
  },
  "error": {
    "notification": "Please refresh the page or contact to the support"
  },
  "label": {
    "Produktdetail": "Product detail",
    "processing": "Processing",
    "AngebotErstellt": "Offer sent",
    "theperfect": "Find your new corporate loan",
    "Sicherheiten": "Collateral",
    "Ratingagentur": "Rating agency",
    "Kreditrating": "Credit rating",
    "atcredittech": "Register as a lender and you will find new credit customers in just a few steps.\n All sectors - Regional - Nationwide",
    "whycredittech": "Why Credit Tech?",
    "atfirst": "At first I Invested little and by time pass I Invested to the sector I like and Credit Tech takes me right there",
    "whatothers": "What others say?",
    "signupto": "Sign Up to Credit Tech as an Investor",
    "enteryourdetails": "Enter your details below.",
    "firstname": "First Name",
    "lastname": "Last Name",
    "companyname": "Company Name",
    "emailaddress": "Email Address",
    "password": "Password",
    "confirmpassword": "Confirm Password",
    "phonenumber": "Phone Number",
    "alreadyhaveanaccount": "Already have an account ? ",
    "login": "Login",
    "banner_desc": "Lorem ipsum dolor sit amet, tempor labore sadipscing over the years, sed diam tempor sed ut labore et dolore magna per month.",
    "ifyoudont": "If you dont have account you can sign up ",
    "youdont": "You dont have any new notifications",
    "enteryouremail": "Enter your email address and password",
    "here": "here",
    "sendit": "Send it again",
    "didnt": "Didn’t recieve an email?",
    "youraccounthas": "Your account has been successfully created. Please check your email for the confirmation",
    "weve": "We’ve just send you an Email",
    "activation": "Activation",
    "adminapproval": "Admin Approval",
    "confirmemail": "Confirm Email",
    "theconfirm": "The confirmation link has expired.",
    "wecant": "We cant verify your email address.",
    "ouradminneed": "Our admin needs to confirm the account first. We will send you an Email when its done",
    "justonemore": "Just one more step, Admin Approval",
    "youcanask": "You can ask for a new validation link here.",
    "youraccounthasbeen": "Your account has been activated. From now on you can use our service freely",
    "wow": "Wow, Welcome to Pihub",
    "attachments": "Attachments",
    "timeduration": "Time duration (months)",
    "investor": "Investor",
    "maxcredit": "Maximum Credit Amount",
    "industries": "Industries",
    "state": "State",
    "service": "Service",
    "producttitle": "Product Title",
    "requested": "Requested",
    "approved": "Approved",
    "rejected": "Rejected",
    "ZuBearbeiten": "To edit",
    "invested": "Invested",
    "allproducts": "All Products",
    "fileupload": "File Upload",
    "no": "No",
    "yes": "Yes",
    "rating": "Rating for Credit",
    "county": "County",
    "editproducts": "Edit Products",
    "mincredit": "Minimum Credit Value",
    "notifications": "Notifications",
    "pagenot": ":( 404 - Page Not Found.",
    "thepage": "The page you are looking for might have been removed had its name changed or is temporarily unavailable.",
    "back": "Back to Homepage ",
    "logout": "Logout",
    "accept": "Accept",
    "requestedamount": "Requested amount",
    "time": "Time duration",
    "deadline": "Deadline",
    "applicationcode": "Application code",
    "creditrequests": "Credit requests",
    "Kreditorname": "Lender",
    "Corporateloan": "Corporate loan ",
    "purchase": "Purchase financing / Finetrading",
    "stocktrading": "Stocktrading",
    "acquisition": "Acquisition / Takeover financing",
    "project": "Project financing",
    "mezzanine": "Mezzanine financing",
    "youdonot": "You do not have any products yet! ",
    "profile": "Profile",
    "editprofile": "Edit Profile",
    "minimumsales": "Minimum Sales Creditor",
    "resetPassword": "Reset Password",
    "months": "Months",
    "open": "Open",
    "postponed": "Postponed",
    "deleted": "Deleted",
    "deletedmsg": "This product has been deleted",
    "postponedmsg": "This product has been postponed. You can change the status",
    "suspended": "Suspended",
    "reject": "Reject",
    "forgotPassword": "Forgot Password?",
    "enterEmailForgotten": "Enter your email address.",
    "status": "Status",
    "ratingForCredit": "Rating for credit",
    "nda": "NDA",
    "deadlineForPayment": "Deadline for payment",
    "deadine": "Deadline",
    "collaterals": "Collaterals",
    "salesAmount": "Sales Amount",
    "reasons": "Reasons",
    "passwordChange": "You have successfully changed your password.",
    "newPassword": "with your new password",
    "investorAttachments": "Investor Attachments",
    "otherContactPerson": "Further Contact Person",
    "headquarter": "Headquarter",
    "streetAddress": "Street Address",
    "postalCode": "Zip/Postal Code",
    "contactPerson": "Contact Person",
    "socialMedia": "Social Media",
    "investorCategory": "Investor Category",
    "editProfile": "Edit Profile",
    "update": "Update",
    "accountNotVerified": "Account not verified yet!",
    "accountNotConfirmed": "Account not confirmed yet!",
    "contact": "Contact",
    "loginTitle": "Your new borrower in just a few steps",
    "loginDescription": "Individual | All industries All credit products Regional | Nationwide | from EUR 1,000,000",
    "confirmationLink": "Confirmation Link",
    "passwordChangedSuccessful": "Successfully Password Changed",
    "youCanUseYourNewPassword": "You can use your new password to log in to your creditech account.",
    "withYourNewPassword": "with your new password",
    "weJustSent": "We just sent you an email",
    "anEmailWithInstruction": "An Email with an instructions to reset your email has been sent to",
    "ratingaggentur": "Rating agency",
    "resetYourPassword": "Reset your password",
    "enterAnEmailAssociated": "Enter an email associated with your account",
    "changePassword": "Change Password?",
    "all": "All",
    "canceled": "Canceled",
    "expired": "Expired",
    "agreement": "Agreement",
    "offerLetter": "Offer Letter",
    "makeAnOffer": "Make an Offer",
    "weCantseem": "Sorry we can't seem to find the page you're looking for",
    "takeMeHome": "Take me home",
    "minNetProfit": "Minimum Net Profit",
    "onReview": "On review",
    "pleaseComplete": "Please complete your profile before you fill up form."
  },
  "placeholder": {
    "select": "Select",
    "and": "and ",
    "privacy_policy_start": "Our ",
    "privacy_policy": "privacy policy",
    "privacy_policy_ending": " can be found here.",
    "noActiveProduct": "You haven't invested on any products yet.",
    "pleaseWait": "Einen Moment bitte...",
    "noCreditRequests": "This product doesn't have any credit request.",
    "justASecond": "Please wait a moment...",
    "selectAllStates": "Select All States",
    "selectAllCounties": "Select All Counties",
    "selectAll": "Select All",
    "notAvailable": "Not available",
    "changeProfilePic": "Change company picture",
    "verified": "Verified",
    "unverified": "Unverified",
    "enterYourWebsite": "Enter your website URL",
    "companyName": "Company Name inc.",
    "headquarterLocation": "Headquarter Location",
    "contactName": "Contact Name",
    "contactName2": "Contact Name-2",
    "contactName3": "Contact Name-3",
    "contactEmail": "Contact Email",
    "contactEmail2": "Contact Email-2",
    "contactEmail3": "Contact Email-3",
    "contactPhoneNo": "Contact phone number",
    "contactPhoneNo2": "Contact phone number-2",
    "contactPhoneNo3": "Contact phone number-3",
    "facebookURL": "Facebook URL",
    "twitterURL": "Twitter URL",
    "LinkedinURL": "Linkedin URL",
    "xingURL": "Xing URL",
    "NewPassword": "New Password",
    "Retypepassword": "Retype password"
  },
  "sidebar": {
    "product": "Product",
    "new_product": "New Product",
    "invested_products": "Invested Products",
    "products": "Products",
    "credit_requested_products": "Credit Request"
  },
  "statuses": {
    "offer": {
      "open": "open",
      "on_review": "On review",
      "accepted_by_investor": "Offer Sent",
      "offer_rejected": "Offer Ignored",
      "rejected_by_creditor": "Rejected",
      "accepted_by_creditor": "Accepted"
    },
    "product": {}
  },
  "tooltip": {
    "productName": "Give a product name",
    "documents": "Upload necessary documents.",
    "rating": "Select Yes if rating is required.",
    "collateral": "Select yes if collateral is needed.",
    "minSales": "Minimum Sales Credit from 0 to 50M",
    "maxCreditAmount": "Maximum Credit Amount from 250k to 5m and should be more than the min. credit amount",
    "minCreditAmount": "Minimum Credit Amount from 250k to 5m",
    "timeDuration": "Time dutaion from 12 Months to 60 Months",
    "industry": "Select relevent industry.You can select more than one.",
    "county": "Select one or more counties.",
    "service": "Select a relevant service.",
    "states": "Select states.",
    "phone_number": "Please enter your contact number selecting your country",
    "password": " Your password must contain at least one capital letter, one special character and one number",
    "email_address": "Enter your email address",
    "company_name": "Please enter the name of your company here.  We do not process inquiries from private persons",
    "last_name": "Please enter you family name",
    "first_name": "Please enter your first name",
    "headquarter": "Please enter the state where your company is located.",
    "street_address": "Please enter your company's street address. ",
    "postal_code": "Please enter your company's postal code",
    "contact_person": "Please enter the person's name, email address, and contact number",
    "contact_person_2": "Please enter the second person's name, email address, and contact number",
    "contact_person_3": "Please enter the third person's name, email address, and contact number",
    "social_media": "Please enter URL for social media",
    "file": "Upload any other important files if you have any.",
    "investor_category": "Please select the category from list below.",
    "min_net_profit": "Minimum Net Profit from 0 to 5m"
  },
  "unconfirmed": {
    "msg": "We’ve just send you an Email! Please check your email for the confirmation link.",
    "emailSent": "We’ve just send you an Email!"
  },
  "unverified": {
    "msg": "Your account is not yet verified yet. Please contact the administrator"
  },
  "validation": {
    "required": "* Required",
    "email": "Invalid email address",
    "phoneNumber": "Invalid phone number, must be 10 digits",
    "number": "* Must be a number",
    "same": "Password Mismatch",
    "password": "Password must contain atleast one Capital letter, one small letter, a special character (@, #, $) , one numeric value and must be 8 digit long",
    "newEmail": "Invalid Email!",
    "pleaseAcceptTermsAndCondition": "Please accept terms and condition",
    "fillAllRequiredFields": "Please fill all the required fields."
  }
};
