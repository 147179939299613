import client from "./index";
import { routes } from "./../_api/routes";
import { ERROR, GET_PROFILE, LOADING, LOADING_DONE } from "../actions/types";
import { extractServerErrors } from "../_utils/validate";

export const getProfile = id => async dispatch => {
  try {
    dispatch({
      type: LOADING,
      payload: { URL: routes.getProfile, method: "get", data: id }
    });
    const response = await client.get(routes.getProfile);
    dispatch({
      type: GET_PROFILE,
      payload: response.data.data
    });
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data)
      });
    }
  } finally {
    dispatch({
      type: LOADING_DONE,
      payload: { URL: routes.getProfile, method: "get", data: id }
    });
  }
};

export const editProfile = (details, callback) => async dispatch => {
  dispatch({
    type: LOADING,
    payload: { URL: routes.getProfile, method: "post", data: details }
  });

  try {
    const body = new FormData();

    for (let field in details) {
      if (
        field !== "company_logo_link" &&
        field !== "profile_pic_link" &&
        field !== "status" &&
        field !== "id" &&
        field !== "document_link"
      ) {
        body.append(field, details[field]);
      }
    }
    if (details.document) {
      details.document.forEach((file, index) => {
        body.append(`document[${index}]`, file);
      });
    }
    if (details.category === "") {
      body.set("category", "bank");
    }
    if (details.company_logo) {
      body.append("company_logo", details.company_logo);
    }
    if(details.existing_files){
      body.set("exist_files", details.existing_files);
    }
    body.append("_method", "put");
    const response = await client.post(routes.getProfile, body);
    callback(response);
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data)
      });
    }
  } finally {
    dispatch({
      type: LOADING_DONE,
      payload: { URL: routes.getProfile, method: "post", data: details }
    });
  }
};
