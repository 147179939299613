import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteProduct,
  getProductById,
  postponeProduct
} from "../../actions/product";
import { downloadFile } from "../../actions/download";
import RequestedByList from "../credits/RequestedByList";
import Translate from "react-translate-component";
import { ToEuro } from "../general/CurrencyFormatter";
import { hideFlashMessage, showFlashMessage } from "../../actions/flashMessage";
import Loading from "../general/Loading";
import FileList from "../general/FileList";

class ViewProduct extends Component {
  componentDidMount() {
    if (!this.props.location.state) {
      return this.props.history.push("/products");
    }
    this.props.getProductById(this.props.location.state.id);
  }

  listIndustries = industries => {
    return industries.map(industry => {
      return (
        <Link to="" key={industry.id} className="mb-1">
          {industry.name[this.props.language]}
        </Link>
      );
    });
  };

  listRating = ratings => {
    if (ratings.length === 0) {
      return (
        <span className="col-12">
          **
          <Translate content="column.norating" />
        </span>
      );
    }
    return ratings.map((rating, index) => {
      return (
        <div key={index} className="col-4">
          <h6 className="product__title">{rating.name[this.props.language]}</h6>
          <p className="product__info">{rating.value}</p>
        </div>
      );
    });
  };

  render() {
    if (this.props.product) {
      const {
        product: {
          id,
          collatoral,
          max_credit_amount,
          min_credit_amount,
          industries,
          status,
          min_time_duration,
          max_time_duration,
          product_title,
          service,
          states,
          ratings,
          counties,
          documents,
          min_sales_creditor
        }
      } = this.props.product;
      return (
        <Fragment>
          <Loading />
          {status === "deleted" ? (
            <div className="alert alert-rejected">
              <Translate content="label.deletedmsg" />
            </div>
          ) : null}
          {status === "postponed" ? (
            <div className="alert alert-secondary">
              <Translate content="label.postponedmsg" />
            </div>
          ) : null}
          <div className="content-head">
            <div className="content-head-left">
              <Translate
                content="label.Produktdetail"
                component="h1"
                className="content-head__title"
              />
            </div>
            {status !== "deleted" ? (
              <div className="content-head-right">
                <Link
                  to={{
                    pathname: "/edit-product",
                    state: { id: id }
                  }}
                  className="btn btn-primary"
                >
                  <Translate content="button.Produktbearbeiten" />
                </Link>
              </div>
            ) : null}
          </div>
          <div className="content-body product-details">
            <div className="product-details row">
              <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                <div className="row">
                  <div className="col-4 product-info">
                    <Translate content="label.producttitle" component="h6" />
                    <p className="product__info">{product_title}</p>
                  </div>
                  <div className="col-4 product-info">
                    <Translate content="label.service" component="h6" />
                    <p className="product__info">
                      {service ? service.name[this.props.language] : null}{" "}
                    </p>
                  </div>
                  <div className="col-4 product-info">
                    <Translate content="label.state" component="h6" />
                    <div className="product-info-detail">
                      {states
                        ? states.map(state => {
                            return (
                              <Link to="" key={state.id} className="mb-1">
                                {state.name}
                                <br />
                              </Link>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4 product-info">
                    <Translate content="label.county" component="h6" />

                    <div className="product-info-detail">
                      {counties
                        ? counties.map(county => {
                            return (
                              <Link to="" key={county.id} className="mb-1">
                                {county.name}
                                <br />
                              </Link>
                            );
                          })
                        : null}{" "}
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4 offset-lg-4 product-info">
                    <Translate content="label.industries" component="h6" />
                    <div className="d-flex flex-wrap justify-content-between flex-column">
                      <div className="product-info-detail">
                        {industries ? this.listIndustries(industries) : null}{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 product-info">
                    <Translate content="label.Sicherheiten" component="h6" />
                    {collatoral ? (
                      <Translate content="label.yes" />
                    ) : (
                      <Translate content="label.no" />
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 product-info">
                    <h6 className="product__title">Rating for Credit</h6>
                    <div className="row">
                      {ratings ? this.listRating(ratings) : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-4 col-lg-4 product-details-right">
                <div className="row">
                  <div className="col-12 mb-3">
                    <Translate content="label.maxcredit" component="h6" />
                    <span className="product__info">
                      <ToEuro amount={max_credit_amount} />
                    </span>
                  </div>
                  <div className="col-12 mb-3">
                    <Translate content="label.minimumsales" component="h6" />
                    <span className="product__info">
                      <ToEuro amount={min_sales_creditor} />
                    </span>
                  </div>
                  <div className="col-12 mb-3">
                    <Translate
                      content="column.minimum_credit_amount"
                      component="h6"
                    />
                    <ToEuro amount={min_credit_amount} />
                  </div>
                  <div className="col-12 mb-3">
                    <Translate content="column.minduration" component="h6" />
                    {min_time_duration} <Translate content="label.months" />
                  </div>
                  <div className="col-12">
                    <Translate content="column.maxduration" component="h6" />
                    {max_time_duration} <Translate content="label.months" />
                  </div>
                </div>
              </div>
            </div>

            <div className="product-info-files product-info">
              <Translate content="label.attachments" component="h6" />
              {documents ? <FileList documents={documents} /> : null}
            </div>
            {status !== "deleted" ? (
              <Fragment>
                <button
                  className="btn btn-danger"
                  onClick={() =>
                    this.props.deleteProduct(id, () => {
                      this.props.history.push("/products");
                      this.props.showFlashMessage("alert.product_delete");
                      setTimeout(() => {
                        this.props.hideFlashMessage();
                      }, 5000);
                    })
                  }
                >
                  <Translate content="button.delete" />
                </button>
                &nbsp;&nbsp;
                {status !== "postponed" ? (
                  <button
                    className="btn btn-warning"
                    onClick={() =>
                      this.props.postponeProduct(id, "postpone", () => {
                        this.props.history.push("/products");
                        this.props.showFlashMessage("alert.product_postpone");
                        setTimeout(() => {
                          this.props.hideFlashMessage();
                        }, 5000);
                      })
                    }
                  >
                    <Translate content="button.postpone" />
                  </button>
                ) : (
                  <button
                    className="btn btn-warning"
                    onClick={() =>
                      this.props.postponeProduct(id, "undo_postpone", () => {
                        this.props.history.push("/products");
                        this.props.showFlashMessage(
                          "alert.product_postpone_undo"
                        );
                        setTimeout(() => {
                          this.props.hideFlashMessage();
                        }, 5000);
                      })
                    }
                  >
                    <Translate content="button.undopostpone" />
                  </button>
                )}
              </Fragment>
            ) : null}

            {id ? <RequestedByList id={id} name={product_title} /> : null}
          </div>
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return { product: state.singleProduct, language: state.language };
}

export default connect(mapStateToProps, {
  getProductById,
  deleteProduct,
  postponeProduct,
  downloadFile,
  showFlashMessage,
  hideFlashMessage
})(ViewProduct);
