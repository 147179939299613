import client from "./index";
import { routes } from "./../_api/routes";
import {
  GET_APPLICATION_LIST,
  GET_APPLICATION_DETAIL,
  PAGINATION,
  LOADING,
  LOADING_DONE,
  ERROR
} from "../actions/types";
import clientWithForm from "./formDataRequest";
import { extractServerErrors } from "../_utils/validate";

export const getApplicationList = id => async dispatch => {
  dispatch({
    type: LOADING,
    payload: {
      URL: `${routes.getApplicationList}/${id}/applications`,
      method: "get",
      data: id
    }
  });
  try {
    const response = await client.get(
      `${routes.getApplicationList}/${id}/applications`
    );
    dispatch({
      type: GET_APPLICATION_LIST,
      payload: response.data
    });
    dispatch({
      type: PAGINATION,
      payload: response.data.meta
    });
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data)
      });
    }
  } finally {
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.getApplicationList}/${id}/applications`,
        method: "get",
        data: id
      }
    });
  }
};

export const getApplicationDetail = (pID, aId) => async dispatch => {
  dispatch({
    type: LOADING,
    payload: {
      URL: `${routes.getApplicationDetail}/${pID}/applications/${aId}`,
      method: "get",
      data: pID,
      aId
    }
  });
  try {
    const response = await client.get(
      `${routes.getApplicationDetail}/${pID}/applications/${aId}`
    );
    dispatch({
      type: GET_APPLICATION_DETAIL,
      payload: response.data.data
    });
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data)
      });
    }
  } finally {
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.getApplicationDetail}/${pID}/applications/${aId}`,
        method: "get",
        data: pID,
        aId
      }
    });
  }
};

const prepareDataForSendingOfferRequest = details => {
  const body = new FormData();
  body.set("cover_letter", details.offerLetter);
  if (details.files) {
    details.files.map((file, index) => body.append(`files[${index}]`, file));
  } else {
    body.append("files", null);
  }
  return body;
};
export const sendOfferRequest = (
  pID,
  aId,
  details,
  callback
) => async dispatch => {
  dispatch({
    type: LOADING,
    payload: {
      URL: `${routes.getApplicationDetail}/${pID}/applications/${aId}/files`,
      method: "post",
      data: details
    }
  });
  try {
    const response = await clientWithForm.post(
      `${routes.getApplicationDetail}/${pID}/applications/${aId}/files`,
      prepareDataForSendingOfferRequest(details)
    );
    callback(response.data);
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data)
      });
    }
  } finally {
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.getApplicationDetail}/${pID}/applications/${aId}/files`,
        method: "post",
        data: details
      }
    });
  }
};

export const comment = (
  productId,
  applicationId,
  details
) => async dispatch => {
  try {
    let body = new FormData();
    body.set("comment", details.comment);
    await client.post(
      `${routes.getApplicationDetail}/${productId}/applications/${applicationId}/comments`,
      body
    );
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data)
      });
    }
  }
};
