import client from "./index";
import clientWithForm from "./formDataRequest";
import { routes } from "./../_api/routes";
import {
  PRODUCTS_LIST,
  ERROR,
  SINGLE_PRODUCT,
  PAGINATION,
  LOADING,
  LOADING_DONE
} from "./types";
import { extractServerErrors } from "../_utils/validate";

const Translator = require("react-translate-component");

export const getProductsList = (
  page,
  status,
  product_title
) => async dispatch => {
  dispatch({
    type: LOADING,
    payload: {
      URL: `${routes.products}?page=${page}`,
      method: "get",
      data: page,
      status,
      product_title
    }
  });
  try {
    let response = await client.get(
      `${routes.products}?page=${page}` +
        (status ? `&status=${status}` : "") +
        (product_title ? `&product_title=${product_title}` : "")
    );

    dispatch({
      type: PRODUCTS_LIST,
      payload: response.data
    });
    dispatch({
      type: PAGINATION,
      payload: response.data.meta
    });
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data)
      });
    }
  } finally {
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.products}?page=${page}`,
        method: "get",
        data: page,
        status,
        product_title
      }
    });
  }
};

const prepareProductDataForAdding = details => {
  let body = new FormData();
  body.set("product_title", details.product_title);
  body.set("state_ids", details.state_ids.filter(Number).toString());
  body.set("county_ids", details.county_ids.filter(Number).toString());
  body.append("industry_ids", details.industry_id.filter(Number).toString());
  body.set("service_id", details.services.value);
  body.set("min_credit_amount", details.min_credit_amount);
  body.set("max_credit_amount", details.max_credit_amount);
  body.set("min_sales_creditor", details.min_sales_creditor);
  body.set("min_net_profit", details.min_net_profit);
  if (details.files) {
    details.files.map((file, index) => body.append(`files[${index}]`, file));
  }
  body.set("collatoral", details.collatoral === "true" ? 1 : 0);
  body.set("rating_for_credit", details.rating_for_credit === "true" ? 1 : 0);
  body.set("ratings", JSON.stringify(details.ratings));
  body.set("min_time_duration", details.min_time_duration);
  body.set("max_time_duration", details.max_time_duration);
  if(details.existing_files){
    body.set("existing_files", JSON.stringify(details.existing_files));
  }

  return body;
};

export const addProduct = (details, callback) => async dispatch => {
  dispatch({
    type: LOADING,
    payload: { URL: `${routes.addProduct}`, method: "post", data: details }
  });
  try {
    await clientWithForm.post(
      routes.addProduct,
      prepareProductDataForAdding(details)
    );
    callback();
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data)
      });
    }
  } finally {
    dispatch({
      type: LOADING_DONE,
      payload: { URL: `${routes.addProduct}`, method: "post", data: details }
    });
  }
};

const prepareProductDataForConsuming = data => {
  const preparedData = {};
  preparedData.counties = data.counties;
  preparedData.industries = data.industries;
  preparedData.services = {
    value: data.service.id,
    label: data.service.name[Translator.getLocale()]
  };
  preparedData.collatoral = data.collatoral === 1 ? "true" : "false";
  preparedData.rating_for_credit =
    data.ratings && data.ratings.length > 0 ? "true" : "false";
  for (let ratings of data.ratings) {
    preparedData["cr_value_" + ratings.id] = ratings.value;
    preparedData["checkbox_" + ratings.id] = true;
  }

  return preparedData;
};

export const getProductById = id => async dispatch => {
  try {
    dispatch({
      type: LOADING,
      payload: {
        URL: `${routes.getProductById}/${id}`,
        method: "get",
        data: id
      }
    });
    const response = await client.get(`${routes.getProductById}/${id}`);
    dispatch({
      type: SINGLE_PRODUCT,
      payload: {
        ...response.data.data,
        ...prepareProductDataForConsuming(response.data.data)
      }
    });
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data)
      });
    }
  } finally {
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.getProductById}/${id}`,
        method: "get",
        data: id
      }
    });
  }
};

const prepareProductDataforUpdating = details => {
  const body = prepareProductDataForAdding(details);
  body.set("_method", "PUT");

  return body;
};

export const updateProduct = (details, id, callback) => async dispatch => {
  dispatch({
    type: LOADING,
    payload: {
      URL: `${routes.addProduct}/${id}`,
      method: "post",
      data: details
    }
  });

  try {
    await clientWithForm.post(
      `${routes.addProduct}/${id}`,
      prepareProductDataforUpdating(details)
    );
    callback();
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data)
      });
    }
  } finally {
    dispatch({
      type: LOADING_DONE,
      payload: {
        URL: `${routes.addProduct}/${id}`,
        method: "post",
        data: details
      }
    });
  }
};

export const deleteProduct = (id, callback) => async dispatch => {
  try {
    await client.delete(`${routes.addProduct}/${id}`);
    callback();
  } catch (e) {
  }
};

export const postponeProduct = (id, status, callback) => async dispatch => {
  try {
    await client.put(`${routes.products}/${id}/status`, {
      action: status
    });
    callback();
  } catch (e) {}
};
