import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import {
  checkBoxTermsConditions,
  inputField,
  renderPhone
} from "../../_formFields";
import * as validation from "../../_utils/validate";
import { displayServerErrors } from "../../_utils/validate";
import { signup } from "../../actions/signup";
import { clearError, clearLoading } from "../../actions/clearError";
import { connect } from "react-redux";
import { compose } from "redux";

import Translate from "react-translate-component";
import * as flashMessage from "../../actions/flashMessage";
import Loading from "../general/Loading";
import ReactTooltip from "react-tooltip";
import LanguageFlags from "../general/LanguageFlags";

class Signup extends Component {
  state = { phone: "" };

  componentDidMount() {
    this.props.clearError();
    this.props.clearLoading();
  }

  onSubmit = formProps => {
    this.props.signup(formProps, () => {
      this.props.history.push("/signup/confirm-email");
    });
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="container-full-width">
        <div className="panel-container">
          <div className="row w-100">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 feature-container feature-container--signup">
              <a href=" https://www.pihub-pi.com/de/" target="_blank">
                <img
                  className="company-logo"
                  src="/assets/img/logo.png"
                  alt="company logo"
                />
              </a>
              <div className="feature-sidebar">
                <div className="feature-ours">
                  <Translate
                    content="label.theperfect"
                    component="h3"
                    className="feature-ours__title"
                  />
                  <Translate
                    content="label.atcredittech"
                    component="p"
                    className="feature-ours__sub-title"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 main-container">
              <div className="signup-form-container">
                <nav className="header-actions">
                  <LanguageFlags />
                </nav>
                <Loading />
                <header className="page-header">
                  <Translate
                    content="label.signupto"
                    component="h1"
                    className="page-title"
                  />
                  <Translate
                    content="label.enteryourdetails"
                    component="p"
                    className="page-desc"
                  />
                </header>
                <form
                  className="form-signup"
                  onSubmit={handleSubmit(this.onSubmit)}
                >
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label>
                          <Translate content="label.firstname" />
                        </label>
                        <i
                          data-tip
                          data-for="first_name"
                          className=" bx bx-info-circle"
                        >
                          <ReactTooltip id="first_name" type="info">
                            <Translate content="tooltip.first_name" />
                          </ReactTooltip>
                        </i>
                        <Field
                          name="fname"
                          type="text"
                          component={inputField}
                          className="form-control"
                          validate={validation.required}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label>
                          <Translate content="label.lastname" />
                        </label>
                        <i
                          data-tip
                          data-for="last_name"
                          className=" bx bx-info-circle"
                        >
                          <ReactTooltip id="last_name" type="info">
                            <Translate content="tooltip.last_name" />
                          </ReactTooltip>
                        </i>
                        <Field
                          name="lname"
                          type="text"
                          component={inputField}
                          className="form-control"
                          validate={validation.required}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      <Translate content="label.companyname" />
                    </label>
                    <i
                      data-tip
                      data-for="company_name"
                      className=" bx bx-info-circle"
                    >
                      <ReactTooltip id="company_name" type="info">
                        <Translate content="tooltip.company_name" />
                      </ReactTooltip>
                    </i>
                    <Field
                      name="company_name"
                      type="text"
                      component={inputField}
                      className="form-control"
                      validate={validation.required}
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      <Translate content="label.emailaddress" />
                    </label>
                    <i
                      data-tip
                      data-for="email_address"
                      className=" bx bx-info-circle"
                    >
                      <ReactTooltip id="email_address" type="info">
                        <Translate content="tooltip.email_address" />
                      </ReactTooltip>
                    </i>
                    <Field
                      name="email"
                      type="email"
                      component={inputField}
                      className="form-control"
                      validate={validation.required}
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      <Translate content="label.password" />
                    </label>
                    <i
                      data-tip
                      data-for="password"
                      className=" bx bx-info-circle"
                    >
                      <ReactTooltip id="password" type="info">
                        <Translate content="tooltip.password" />
                      </ReactTooltip>
                    </i>
                    <Field
                      name="password"
                      type="password"
                      component={inputField}
                      className="form-control"
                      validate={validation.required}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      name="password_confirmation"
                      type="password"
                      component={inputField}
                      label={<Translate content="label.confirmpassword" />}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      <Translate content="label.phonenumber" />
                    </label>
                    <i
                      data-tip
                      data-for="phone_number"
                      className=" bx bx-info-circle"
                    >
                      <ReactTooltip id="phone_number" type="info">
                        <Translate content="tooltip.phone_number" />
                      </ReactTooltip>
                    </i>
                    <Field
                      component={renderPhone}
                      regions="europe"
                      placeholder="+49 30 12345-678"
                      name="phone_number"
                      inputExtraProps={{
                        name: "phone_number",
                        required: true
                      }}
                      country="de"
                      validate={validation.required}
                    />
                  </div>
                  <Field
                    name="agreed_term"
                    component={checkBoxTermsConditions}
                    type="checkbox"
                  />
                  {this.props.errMsg
                    ? displayServerErrors(this.props.errMsg)
                    : null}
                  <Translate
                    content="button.signup"
                    className="btn btn-primary btn-form"
                    type="submit"
                    component="button"
                  />
                </form>
                <br />
                <Translate content="label.alreadyhaveanaccount" />
                &nbsp;
                <Link to="/">
                  <strong>
                    <Translate content="label.login" />
                  </strong>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};

  if (values.email) {
    errors.email = validation.email(values.email);
  }
  if (values.password) {
    errors.password = validation.password(values.password);
    if (values.password !== values.password_confirmation) {
      errors.password_confirmation = validation.same(
        values.password_confirmation
      );
    }
  }
  if (!values.agreed_term) {
    errors.agreed_term = "validation.pleaseAcceptTermsAndCondition";
  }
  return errors;
}

function mapStateToProps(state) {
  return { errMsg: state.errors };
}

export default compose(
  connect(mapStateToProps, {
    signup,
    clearError,
    clearLoading,
    ...flashMessage
  }),
  reduxForm({
    validate,
    form: "signup"
  })
)(Signup);
