import client from "./index";
import { routes } from "./../_api/routes";
import {
  NOTIFICATION_COUNT,
  ERROR,
  LIST_NOTIFICATION,
  LOADING,
  LOADING_DONE
} from "../actions/types";
export const getNotificationCount = () => async dispatch => {
  try {
    dispatch({
      type: LOADING,
      payload: { URL: routes.countNotification, method: "get", data: null }
    });
    const response = await client.get(routes.countNotification);
    dispatch({
      type: NOTIFICATION_COUNT,
      payload: response.data
    });
    dispatch({
      type: LOADING_DONE,
      payload: { URL: routes.countNotification, method: "get", data: null }
    });
  } catch (e) {
    dispatch({
      type: ERROR,
      payload: e.response
        ? e.response.data.message
        : "Unable to fetch notification count!"
    });
    dispatch({
      type: LOADING_DONE,
      payload: { URL: routes.countNotification, method: "get", data: null }
    });
  }
};

export const getNotificationList = page => async dispatch => {
  try {
    dispatch({
      type: LOADING,
      payload: { URL: routes.getNotificationList, method: "get", data: null }
    });
    const response = await client.get(
      `${routes.getNotificationList}?page=${page}`
    );
    dispatch({
      type: LIST_NOTIFICATION,
      payload: response.data.data
    });
    dispatch({
      type: LOADING_DONE,
      payload: { URL: routes.getNotificationList, method: "get", data: null }
    });
  } catch (e) {
    if (e.response.status === 500) {
      dispatch({
        type: ERROR,
        payload: "error.notification"
      });
      dispatch({
        type: LOADING_DONE,
        payload: { URL: routes.getNotificationList, method: "get", data: null }
      });
    } else {
      dispatch({
        type: ERROR,
        payload: e.response
          ? e.response.data.message
          : "Unable to fetch list of notification"
      });
      dispatch({
        type: LOADING_DONE,
        payload: { URL: routes.getNotificationList, method: "get", data: null }
      });
    }
  }
};

export const markAsRead = (id, callback) => async dispatch => {
  try {
    let ids = { notification_ids: [id] };
    const response = await client.post(routes.markAsRead, ids);
    if (response) {
      callback();
    }
  } catch (e) {}
};
