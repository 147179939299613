import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getApplicationDetail } from "../../actions/application";
import Translate from "react-translate-component";
import { changeStatus } from "../../actions/changeStatus";
import CreditInfo from "./CreditInfo";
import * as flashMessage from "../../actions/flashMessage";
import { clearError, clearLoading } from "../../actions/clearError";
import Loading from "../general/Loading";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { textAreaField } from "../../_formFields";
import { compose } from "redux";

class DetailCreditRequest extends Component {
  state = { detail: null, refresh: false, show: true };

  componentDidMount() {
    this.props.clearError();
    this.props.clearLoading();
    if (!this.props.location.state) {
      return this.props.history.push("/products");
    }
    const { pId, aId } = this.props.location.state;
    this.props.getApplicationDetail(pId, aId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data !== prevProps.data) {
      this.setState({ detail: this.props.data.detail });
    }
    if (this.state.refresh !== prevState.refresh) {
      const { pId, aId } = this.props.location.state;
      this.props.getApplicationDetail(pId, aId);
    }
  }

  renderDocs = docs => {
    if (docs.length === 0) {
      return (
        <span>
          <Translate content="column.noattachment" />
        </span>
      );
    } else {
      return docs.map((doc, index) => {
        return (
          <div className="file mb-2">
            <span className="file-name">File {index + 1}</span>
            <span className="ml-4 file-size">Type: {doc.type}</span>
          </div>
        );
      });
    }
  };

  /**
   * Loop through collection to show objects display properties
   **/
  showCollections = collection => {
    if (collection.length === 0) {
      return <div>Not Available</div>;
    } else {
      return collection.map((object, index) => {
        return (
          <span key={index}>
            {object.name} <br />
          </span>
        );
      });
    }
  };
  /**
   * @params = {
   *     Boolean ratingForCredit,
   *     Array creditRatings
   * }
   * Render Credit Ratings
   **/
  renderCreditRatings = (ratingForCredit, creditRatings) => {
    if (ratingForCredit) {
      return (
        <Fragment>
          <div className="row justify-content-between w-100 mt-5">
            <div className="col-2 p-0">
              <h6>Creditrre form</h6>
              <span>AAA</span>
            </div>
            <div className="col-2 p-0">
              <h6>Standard & Poors</h6>
              <span>A+</span>
            </div>
            <div className="col-2 p-0">
              <h6>Moody's</h6>
              <span>AAA+</span>
            </div>
            <div className="col-2 p-0">
              <h6>Euler Hermes</h6>
              <span>AAA+</span>
            </div>
          </div>
          <div className="row justify-content-between w-100 mt-3">
            <div className="col-3 p-0">
              <h6>Euler Hermes</h6>
              <span>AAA+</span>
            </div>
            <div className="col-3 p-0">
              <h6>Bank/Andere</h6>
              <span>AAA+</span>
            </div>
          </div>
        </Fragment>
      );
    }
  };
  changeStatus = status => {
    const { pId, aId } = this.props.location.state;

    this.props.changeStatus(pId, aId, status, () =>
      this.setState({ refresh: !this.state.refresh })
    );
  };

  operation = () => {
    this.setState({
      show: !this.state.show
    });
  };

  onSubmit = formProps => {
    const { pId, aId } = this.props.location.state;
    this.props.comment(pId, aId, formProps);
  };

  render() {
    if (this.state.detail) {
      const { pId, aId } = this.props.location.state;
      const { status } = this.state.detail;
      const { handleSubmit } = this.props;

      return (
        <Fragment>
          {/* <Subheader heading={this.props.location.state.product} /> */}
          {status === "on_review" ? (
            <div className="d-flex justify-content-end mb-3">
              <Link
                to={{
                  pathname: "/send-offer",
                  state: {
                    productId: pId,
                    applicationId: aId
                  }
                }}
                className="btn btn-primary"
              >
                <Translate content="label.makeAnOffer" />
              </Link>
            </div>
          ) : null}
          <CreditInfo location={this.props.location} />
          {status === "offer_sent" || status === "rejected" ? null : (
            <span className="mt-3">
              <button
                className="btn btn-success mr-2"
                disabled={status === "on_review"}
                onClick={() => this.changeStatus("on_review")}
              >
                <Translate content="label.onReview" />
              </button>
              <button
                className="btn btn-danger mr-2"
                disabled={status === "rejected"}
                onClick={() => this.changeStatus("rejected")}
              >
                <Translate content="label.reject" />{" "}
              </button>
              <button
                className="btn btn-info "
                onClick={e => {
                  e.preventDefault();
                  this.operation();
                }}
              >
                <Translate content="button.moreDetail" />
              </button>
            </span>
          )}
          {this.state.show ? null : (
            <Fragment>
              <div className="content-head mt-5">
                <div className="content-head-left">
                  <h4 className="content-head__title">
                    <Translate content="button.moreDetail" />
                  </h4>
                </div>
              </div>
              <div className="content-body mt-3">
                <form onSubmit={handleSubmit(this.onSubmit)}>
                  <Field
                    type="text"
                    component={textAreaField}
                    name="comment"
                    id="comment"
                    cols="60"
                    rows="10"
                  />
                  <button className="btn btn-primary mt-4">
                    <Translate content="button.send" />
                  </button>
                </form>
              </div>
            </Fragment>
          )}
        </Fragment>
      );
    } else {
      return <Loading />;
    }
  }
}

function mapStateToProps(state) {
  return { data: state.applicationDetail };
}

export default compose(
  connect(mapStateToProps, {
    getApplicationDetail,
    changeStatus,
    ...flashMessage,
    clearLoading,
    clearError
  }),
  reduxForm({
    form: "comment"
  })
)(DetailCreditRequest);
