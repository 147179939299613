import client from "./index";
import { routes } from "./../_api/routes";
import { GET_INDUSTRY, GET_INDUSTRY_NAME } from "../actions/types";
import { splitIndustries } from "../_utils/misc";
import {ERROR} from "./types";
import {extractServerErrors} from "../_utils/validate";

export const getIndustryList = () => async dispatch => {
  try {
    const response = await client.get(`${routes.getIndustryList}`);

    let industries = splitIndustries(response.data.data);
    dispatch({
      type: GET_INDUSTRY_NAME,
      payload: industries
    });
    dispatch({
      type: GET_INDUSTRY,
      payload: response.data.data
    });
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data)
      });
    }
  }
};
