import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeLanguage } from "../../actions/changeLanguage";
import counterpart from 'counterpart';
import en from '../../_locale/en';
import de from '../../_locale/de';

counterpart.registerTranslations("en", en);
counterpart.registerTranslations("de", de);
counterpart.setLocale(
  localStorage.getItem("language") || navigator.language.split("-")[0] || "de"
);
class LanguageFlags extends Component{
    state = {
        language:
          localStorage.getItem("language") ||
          navigator.language.split("-")[0] ||
          "de"
      };
      onChange = e => {
        // let language = e.target.value;
        counterpart.setLocale(e);
        this.setState({ language: e });
        this.props.changeLanguage(e);
      };
      render() {
          return (
            <ul className="language-changer">
            <li>
              <button onClick={() => this.onChange("en")}>
                <img src="/assets/img/gb.svg" alt="English Language" />
                English
              </button>
            </li>
            <li>
              <button onClick={() => this.onChange("de")}>
                <img src="/assets/img/de.svg" alt="Deutsch Language" />
                Deutsch
              </button>
            </li>
          </ul>
          )
      }
}

function mapStateToProps(state){
    return{language: state.language}
}

export default connect(mapStateToProps, {changeLanguage})(LanguageFlags);