import React, {Component} from 'react';
import Translate from "react-translate-component";

export default class IncompleteProfileMessage extends Component {
    render() {
        return sessionStorage.getItem("profileComplete") === "false" ? (
            <div className="alert alert-rejected">
                <Translate content="label.pleaseComplete"/>
            </div>
        ) : null;
    }
}