import React from "react";
import Translate from "react-translate-component";

const strongRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);

const emailRule = new RegExp("^[A-Za-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{1,4}[^]+$");

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "validation.email"
    : undefined;

export const phoneNumber = value =>
  value &&
  !/^(?=(?:\D*\d){10,15}\D*$)\+?[0-9]{1,3}[\s-]?(?:\(0?[0-9]{1,5}\)|[0-9]{1,5})[-\s]?[0-9][\d\s-]{5,7}\s?(?:x[\d-]{0,4})?$/g.test(
    value
  )
    ? "validation.phoneNumber"
    : undefined;
export const required = value =>
  value || typeof value === "number" ? undefined : "validation.required";

export const number = value =>
  value && isNaN(Number(value)) ? "validation.number" : undefined;

export const same = (value1, value2) =>
  value1 === value2 ? "validation.same" : undefined;

export const password = value =>
  value && !strongRegex.test(value) ? "validation.password" : undefined;

export const newEmail = value =>
  value && !emailRule.test(value) ? "validation.newEmail" : undefined;

export const extractServerErrors = response => {
  let errorList = [];

  if (response.hasOwnProperty("errors")) {
    const errors = response.errors;
    for (let key in errors) {
      errorList = errorList.concat(errors[key]);
    }
  } else if (response.hasOwnProperty("message")) {
    errorList.push(response.message);
  } else {
  }

  return errorList;
};

export const displayServerErrors = errors => (
  <ul className="p-0 mt-2">
    {errors.map((err, index) => {
      return (
        <li className="d-flex mb-1" key={index}>
          <i className="bx bx-x-circle icon-danger">
            <font color="red" className="pl-2 green-text">
              {err}
            </font>
          </i>
        </li>
      );
    })}
  </ul>
);

export const formSubmitFailError = submitFailed => {
  return submitFailed ? (
    <li className="d-flex mb-1">
      <img src="/assets/img/icons/bx-x-circle.svg" alt="alt" />
      <span className="pl-2 green-text">
        <Translate content="validation.fillAllRequiredFields" />
      </span>
    </li>
  ) : null;
};
