import client from "./index";
import { routes } from "./../_api/routes";
import {ERROR, GET_SERVICE} from "./types";
import { splitService } from "../_utils/misc";
import {extractServerErrors} from "../_utils/validate";

export const getServiceList = () => async dispatch => {
  try {
    const response = await client.get(routes.getService);
    const service = splitService(response.data.data);
    dispatch({
      type: GET_SERVICE,
      payload: service
    });
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
            typeof e.response === "undefined"
                ? e.message
                : extractServerErrors(e.response.data)
      });
    }
  }
};
