import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/flashMessage";
import Translate from "react-translate-component";

class FlashMessage extends Component {
  // componentDidMount() {
  //     setTimeout(
  //         () => {
  //             this.props.hideFlashMessage();
  //         },
  //         5000
  //     );
  // }

  render() {
    return (
      <div
        className={
          this.props.data.visible
            ? "alert alert-primary"
            : "hidden alert alert-primary"
        }
      >
        <Translate content={this.props.data.message} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { data: state.flashMessage };
}

export default connect(mapStateToProps, actions)(FlashMessage);
