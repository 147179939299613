import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Subheader from "../general/Subheader";
import { getCreditRequestList } from "../../actions/credits";
import Pagination from "../general/Pagination";
import Translate from "react-translate-component";
import { matchesInvestorStatus } from "../../_status";
import Moment from "react-moment";
import Loading from "../general/Loading";

const Translator = require("react-translate-component");

class ListCreditRequests extends Component {
  componentDidMount() {
    this.props.getCreditRequestList(1);
  }

  renderData = data => {
    if (data.length === 0) {
      return (
        <span>
          <Translate content="placeholder.noCreditRequests" />{" "}
        </span>
      );
    }
    return data.map((product, index) => {
      return (
        <tr key={index}>
          <td>
            {" "}
            {product.status === "invested" ? (
              <Link
                className="res-p-l-1"
                to={{
                  pathname: "/creditor/detail",
                  state: {
                    productId: product.product_id,
                    appId: product.application_id
                  }
                }}
              >
                {product.creditor_name}
              </Link>
            ) : (
              <Link
                to={{
                  pathname: "/application",
                  state: {
                    pId: product.product_id,
                    aId: product.application_id,
                    product: product.name
                  }
                }}
              >
                {product.creditor_name}
              </Link>
            )}
          </td>
          <td>
            <Link
              className="m-0"
              to={{ pathname: "/product", state: { id: product.product_id } }}
            >
              {" "}
              {product.product_title}
            </Link>
          </td>
          <td>{product.service.name[Translator.getLocale()]}</td>
          <td>
            <Moment format="DD.MM.YYYY">{product.created_on}</Moment>
          </td>
          {/* <td className="text-right-piehub-table">{product.number_of_request}</td> */}
          <td className="text-md-right text-left">
            <Moment format="DD.MM.YYYY">{product.deadline}</Moment>
          </td>
          {/* <td className="text-right-piehub-table font-weight-bold">
            €{product.max_credit_amount || 100000}
          </td> */}
          <td className="text-right-piehub-table font-weight-bold">
            <span
              className={`badge ${matchesInvestorStatus[product.status].class}`}
            >
              <Translate
                content={matchesInvestorStatus[product.status].translation_key}
              />
            </span>
          </td>
        </tr>
      );
    });
  };

  render() {
    if (this.props.list) {
      const {
        creditRequests: { data }
      } = this.props.list;
      const { totalPage } = this.props.pagination;
      return (
        <Fragment>
          <Subheader heading={<Translate content="label.creditrequests" />} />

          <div className="content-body">
            <table
              className="table tablesaw-stack"
              data-tablesaw-mode="stack"
              data-tablesaw-minimap="data-tablesaw-minimap"
            >
              <thead>
                <tr>
                  <th data-tablesaw-sortable-col="data-tablesaw-sortable-col">
                    <Translate content="column.creditorsname" />
                    {/* Kreditnehmer */}
                  </th>
                  <th
                    data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                    data-tablesaw-priority="persist"
                    scope="col"
                  >
                    {/* <Translate content='column.industry' /> */}
                    <Translate content="column.productname" />
                  </th>
                  <th
                    data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                    data-tablesaw-priority="persist"
                    scope="col"
                  >
                    <Translate content="column.services" />
                    {/* Kreditart */}
                  </th>
                  <th
                    data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                    data-tablesaw-priority="persist"
                    scope="col"
                  >
                    <Translate content="column.createdon" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </th>
                  <th
                    className="text-md-right text-left"
                    data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                    scope="col"
                  >
                    {/* Fristablauf */}
                    <Translate content="label.deadline" />
                  </th>
                  {/* <th
                    className="text-right-piehub-table"
                    data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                    scope="col"
                  >
                    <Translate content='column.investedamount' />
                  </th> */}
                  <th
                    className="text-right-piehub-table"
                    data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                    scope="col"
                  >
                    <Translate content="column.status" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.renderData(data) ? this.renderData(data) : null}
              </tbody>
            </table>
            <Pagination
              totalPage={totalPage}
              url={page => this.props.getCreditRequestList(page)}
            />
          </div>
        </Fragment>
      );
    } else {
      return <Loading />;
    }
  }
}

function mapStateToProps(state) {
  return {
    list: state.creditRequests,
    pagination: state.pagination
  };
}

export default connect(mapStateToProps, { getCreditRequestList })(
  ListCreditRequests
);
