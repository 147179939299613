import React, { Component } from "react";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";

// eslint-disable-next-line import/no-anonymous-default-export
export default ChildComponent => {
  class ComposedComponent extends Component {
    componentDidMount() {
      this.shouldNavigateAway();
    }

    componentDidUpdate() {
      this.shouldNavigateAway();
    }

    shouldNavigateAway() {
      if (!this.props.auth) {
        localStorage.setItem("redirect", this.props.location.pathname);
        this.props.history.push("/");
      } else {
        const { scopes } = jwt_decode(this.props.auth);
        if (scopes[0] === "unapproved_scope") {
          this.props.history.push("/account-unverified");
        } else if (scopes[0] === "unconfirmed_scope") {
          this.props.history.push("/account-not-confirmed");
        }
      }
    }

    render() {
      return <ChildComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return {
      auth: state.auth.authenticated
    };
  }

  return connect(mapStateToProps)(ComposedComponent);
};
