export const AUTH_USER = "auth_user";
export const AUTH_ERROR = "auth_error";
export const USER_DETAIL = "user_detail";
export const PRODUCTS_LIST = "products_list";
export const PRODUCTS_LIST_ERROR = "products_list_error";
export const ERROR = "error";
export const CLEAR_ERROR = "clear_error";
export const CREDIT_REQUESTS_LIST = "credit_requests_list";
export const LIST_NOTIFICATION = "list_notification";
export const NOTIFICATION_COUNT = "notification_count";
export const SINGLE_PRODUCT = "single_product";
export const PAGINATION = "pagination";
export const GET_INDUSTRY = "get_industry";
export const GET_INDUSTRY_NAME = "get_industry_name";
export const GET_APPLICATION_LIST = "get_application_list";
export const GET_APPLICATION_DETAIL = "get_application_detail";
export const INVESTED_LIST = "get_invested_list";
export const CHANGE_LANGUAGE = "change_language";
export const GET_CREDITOR_DETAIL = "get_creditor_detail";
export const GET_PROFILE = "get_profile";
export const GET_SERVICE = "get_service";
export const SCOPE = "scope";
export const GET_TOKEN_FOR_EMAIL = "get_token_email";
export const GET_ALL_STATES = "get_all_states";
export const GET_ALL_STATES_WITH_ID = "get_all_states_id";
export const GET_COUNTIES = "get_counties";
export const GET_COUNTIES_NAME = "get_counties_name";
export const SHOW_FLASH_MESSAGE = "show_flash_message";
export const HIDE_FLASH_MESSAGE = "hide_flash_message";
export const FETCH_RATINGS = "FETCH_RATINGS";
export const LOADING = "loading";
export const LOADING_DONE = "loading_done";
export const CLEAR_LOADING = "clear_loading";
