import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { confirmEmail } from "../../../actions/confirmEmail";
import { withRouter, Link } from "react-router-dom";
import Translate from "react-translate-component";
import { confirmationLink } from "../../../actions/confirmationLink";
import { logout } from "../../../actions/login";
import LanguageFlags from "../../general/LanguageFlags";

class Approval extends Component {
  state = { invalid: false };

  componentDidMount() {
    const { hash } = this.props.match.params;
    this.props.confirmEmail(
      hash,
      () => {
        // this.props.history.push('/signup/activated')
      },
      () => this.setState({ invalid: true })
    );
  }

  onClick = () => {
    const email = localStorage.getItem("email");
    this.props.confirmationLink(email, () => {
      this.props.history.push("/signup/confirm-email");
    });
  };
  onLoginClick = () => {
    const token = localStorage.getItem("token");
    if (token !== null) {
      this.props.logout(() => this.props.history.push("/"));
    } else {
      this.props.history.push("/");
    }
  };

  render() {
    return (
      <Fragment>
        <nav className="header-actions">
          <Link to="/signup">
            {" "}
            <img
              className="company-logo company-logo-email"
              src="/assets/img/logo.png"
              alt="company logo"
            />
          </Link>
          <LanguageFlags />
        </nav>
        <div className="container-full-height text-centerd d-flex">
          {!this.state.invalid ? (
            <div className="content m-auto">
              <div className="email-staging d-flex justify-content-center justify-content-between position-relative mb-5">
                <span className="line"></span>
                <div className="indicator-container d-flex flex-column align-items-center">
                  <div className="indicator d-flex justify-content-center align-items-center connect">
                    <span>1</span>
                  </div>
                  {/* <span className="mt-3">Confirm Email</span> */}
                  <Translate
                    content="label.confirmemail"
                    component="span"
                    className="mt-3"
                  />
                  <hr />
                </div>
                <div className="indicator-container d-flex flex-column align-items-center">
                  <div className="indicator active d-flex justify-content-center align-items-center">
                    <span>2</span>
                  </div>
                  <Translate
                    content="label.adminapproval"
                    component="span"
                    className="mt-3"
                  />
                </div>
                <div className="indicator-container d-flex flex-column align-items-center">
                  <div className="indicator d-flex justify-content-center align-items-center">
                    <span>3</span>
                  </div>
                  <Translate
                    content="label.activation"
                    component="span"
                    className="mt-3"
                  />
                </div>
              </div>
              <div className="email-content text-center m-auto">
                {" "}
                <img
                  src="/assets/img/icons/admin-approval.png"
                  alt="Mail icon"
                />
                <Translate content="label.justonemore" component="h3" />
                <Translate
                  content="label.ouradminneed"
                  component="p"
                  className="w-75 m-auto"
                />
                <Link onClick={this.onLoginClick}>
                  <strong>
                    {" "}
                    <Translate content="label.login" />
                  </strong>
                </Link>
              </div>
            </div>
          ) : (
            <div className="content m-auto">
              <div className="email-content text-center m-auto">
                {" "}
                <img
                  src="/assets/img/icons/admin-approval.png"
                  alt="Mail icon"
                />
                <Translate content="label.wecant" component="h3" />
                <Translate
                  content="label.theconfirm"
                  component="p"
                  className="w-75 m-auto"
                />
                <Translate content="label.youcanask" component="p" />
                <Link onClick={this.onClick}>
                  <strong>
                    <Translate content="label.confirmationLink" />
                  </strong>
                </Link>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default connect(null, { confirmEmail, confirmationLink, logout })(
  withRouter(Approval)
);
