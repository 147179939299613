import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getInvestedList } from "../../actions/invested";
import Subheader from "../general/Subheader";
import Translate from "react-translate-component";
import { ToEuro } from "../general/CurrencyFormatter";
import Pagination from "../general/Pagination";
import Loading from "../general/Loading";
class InvestedList extends Component {
  state = { investments: null };
  componentDidMount() {
    this.props.getInvestedList();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.investments !== this.props.investments) {
      this.setState({ investments: this.props.investments.list });
    }
  }

  renderList = investments => {
    if (investments.length === 0) {
      return (
        <div>
          <Translate content="placeholder.noActiveProduct" />
        </div>
      );
    } else {
      return investments.map((investment, index) => {
        var investedDate = new Date(investment.invested_on);
        return (
          <tr key={index}>
            <td>
              <Link
                className="res-p-l-1"
                to={{
                  pathname: "/creditor/detail",
                  state: {
                    pId: investment.product_id,
                    aId: investment.application_id
                  }
                }}
              >
                {" "}
                {investment.creditor_name}
              </Link>
            </td>
            <td>
              <Link
                className="m-0"
                to={{
                  pathname: "/product",
                  state: { id: investment.product_id }
                }}
              >
                {" "}
                {investment.product_title}
              </Link>
            </td>
            {/* <td className="text-right-piehub-table">{investment.service}</td> */}
            <td className="text-right-piehub-table">
              {investedDate.getDate()}.{investedDate.getMonth() + 1}.
              {investedDate.getFullYear()}
            </td>

            <td className="font-weight-bold text-right-piehub-table">
              <ToEuro amount={investment.invested_amount} />
            </td>
            <td className="font-weight-bold text-right-piehub-table">
              {investment.duration} Monate
            </td>
          </tr>
        );
      });
    }
  };
  render() {
    const { totalPage } = this.props.pagination;
    return (
      <Fragment>
        <Subheader
          heading={<Translate content="sidebar.invested_products" />}
        />
        <Loading />
        <div className="content-body">
          <table
            className="table tablesaw-stack"
            data-tablesaw-mode="stack"
            data-tablesaw-minimap="data-tablesaw-minimap"
          >
            <thead>
              <tr>
                <th data-tablesaw-sortable-col="data-tablesaw-sortable-col">
                  {/* Kreditnehmer */}
                  <Translate content="column.creditorsname" />
                </th>
                <th
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  data-tablesaw-priority="persist"
                  scope="col"
                >
                  <Translate content="column.productname" />
                  {/* Produktname */}
                </th>
                {/* <th className="text-right-piehub-table" data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                                    scope="col"><Translate content='column.services' /></th> */}
                <th
                  className="text-right-piehub-table"
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="column.approvedon" />
                </th>
                <th
                  className="text-right-piehub-table"
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="column.investedamount" />
                  {/* Kreditbetrag */}
                </th>
                <th
                  className="text-right-piehub-table"
                  data-tablesaw-sortable-col="data-tablesaw-sortable-col"
                  scope="col"
                >
                  <Translate content="column.duration" />
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.investments
                ? this.renderList(this.state.investments)
                : null}
            </tbody>
          </table>
          <Pagination
            totalPage={totalPage}
            url={page => this.props.getInvestedList(page)}
          />
        </div>
      </Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    investments: state.investment,
    pagination: state.pagination
  };
}
export default connect(mapStateToProps, { getInvestedList })(InvestedList);
