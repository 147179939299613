import React, { Component, Fragment } from "react";
import Translate from "react-translate-component";
import { Link } from "react-router-dom";
import { confirmationLink } from "../../../actions/confirmationLink";
import { connect } from "react-redux";
import LanguageFlags from "../../general/LanguageFlags";

class ConfirmEmail extends Component {
  onClick = () => {
    const email = localStorage.getItem("email");
    this.props.confirmationLink(email, () => {});
  };

  render() {
    return (
      <Fragment>
        <nav className="header-actions">
          <img
            className="company-logo company-logo-email"
            src="/assets/img/logo.png"
            alt="company logo"
          />
          <LanguageFlags />
        </nav>
        <div className="container-full-height text-centerd d-flex">
          <div className="content m-auto">
            <div className="email-staging d-flex justify-content-center justify-content-between position-relative mb-5">
              <span className="line"></span>
              <div className="indicator-container d-flex flex-column align-items-center">
                <div className="indicator active d-flex justify-content-center align-items-center connect">
                  <span>1</span>
                </div>
                <Translate
                  content="label.confirmemail"
                  component="span"
                  className="mt-3"
                />
                <hr />
              </div>
              <div className="indicator-container d-flex flex-column align-items-center">
                <div className="indicator d-flex justify-content-center align-items-center">
                  <span>2</span>
                </div>
                <Translate content="label.adminapproval" className="mt-3" />
              </div>
              <div className="indicator-container d-flex flex-column align-items-center">
                <div className="indicator d-flex justify-content-center align-items-center">
                  <span>3</span>
                </div>
                <Translate
                  content="label.activation"
                  component="span"
                  className="mt-3"
                />
              </div>
            </div>
            <div className="email-content text-center w-75 m-auto">
              {" "}
              <img src="/assets/img/icons/Mail.png" alt="Mail icon" />
              <Translate content="label.weve" component="h3" />
              <Translate content="label.youraccounthas" component="p" />
              <Translate content="label.didnt" />
              <Link onClick={this.onClick} className="ml-2">
                <strong>
                  <Translate content="label.sendit" />
                </strong>
              </Link>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, { confirmationLink })(ConfirmEmail);
