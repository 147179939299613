import React, { Component, Fragment } from "react";
import Subheader from "../general/Subheader";
import Translate from "react-translate-component";
import * as actions from "../../actions/product";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Pagination from "../general/Pagination";
import { ToEuro } from "../general/CurrencyFormatter";
import Loading from "../general/Loading";

class ProductsList extends Component {
  state = { status: null, product_title: "" };

  componentDidMount() {
    this.props.getProductsList(1, this.state.status);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.status !== this.state.status) {
      this.props.getProductsList(
        this.props.pagination.currentPage,
        this.state.status
      );
    }
  }

  renderList = productsObject => {
    if (productsObject && productsObject.productsList.data.length > 0) {
      let products = productsObject.productsList.data;
      return products.map((product, index) => {
        return (
          <tr key={product.id}>
            <td>
              <Link
                className="res-p-l-1"
                to={{
                  pathname: `/product`,
                  state: { id: product.id }
                }}
              >
                {product.product_title}
              </Link>
            </td>
            <td>
              {product.service.name ? (
                product.service.name[`${this.props.language}`]
              ) : (
                <Translate content="placeholder.notAvailable" />
              )}
            </td>

            <td>
              {product.industries.map((industry, index) => {
                if (index < 2) {
                  return index === 0 ? (
                    <span key={index}>
                      {industry.name ? (
                        industry.name[`${this.props.language}`]
                      ) : (
                        <Translate content="placeholder.notAvailable" />
                      )}
                    </span>
                  ) : (
                    <Link
                      key={index}
                      to={{
                        pathname: `/product`,
                        state: { id: product.id }
                      }}
                    >
                      &nbsp;...
                    </Link>
                  );
                } else {
                  return "";
                }
              })}
            </td>
            <td>{product.min_time_duration} Monate</td>
            <td>{product.max_time_duration} Monate</td>

            <td>
              <ToEuro amount={product.min_credit_amount} />
            </td>
            <td>
              <ToEuro amount={product.max_credit_amount} />
            </td>
            <td>
              {product.status === "requested" ? (
                <span className="badge badge-warning">
                  <Translate content="label.requested" />
                </span>
              ) : null}
              {product.status === "approved" ? (
                <span className="badge badge-success">
                  <Translate content="label.approved" />
                </span>
              ) : null}
              {product.status === "rejected" ? (
                <span className="badge badge-danger">
                  <Translate content="label.rejected" />
                </span>
              ) : null}
              {product.status === "invested" ? (
                <span className="badge badge-danger">
                  <Translate content="label.invested" />
                </span>
              ) : null}
              {product.status === "open" ? (
                <span className="badge badge-info">
                  <Translate content="label.open" />
                </span>
              ) : null}
              {product.status === "postponed" ? (
                <span className="badge badge-secondary">
                  <Translate content="label.postponed" />
                </span>
              ) : null}
              {product.status === "deleted" ? (
                <span className="badge badge-light">
                  <Translate content="label.deleted" />
                </span>
              ) : null}
              {product.status === "suspended" ? (
                <span className="badge badge-info">
                  <Translate content="label.suspended" />
                </span>
              ) : null}
            </td>
          </tr>
        );
      });
    } else {
      /*@todo handle empty conditions properly with designed layout*/
      return (
        <tr>
          <td>
            <Translate content="label.youdonot" />
          </td>
        </tr>
      );
    }
  };

  render() {
    const { totalPage } = this.props.pagination;
    return (
      <Fragment>
        <Subheader
          heading={<Translate content="label.allproducts" />}
          buttonLabel={<Translate content="button.addnewproduct" />}
          link="/add-product"
        />
        <Loading />
        <form className="form-inline my-2 my-lg-0">
          <select
            className="form-control mr-sm-2"
            onChange={e => this.setState({ status: e.target.value })}
          >
            <Translate
              attributes={{ label: "label.all" }}
              component="option"
              value=""
            />
            <Translate
              attributes={{ label: "label.approved" }}
              component="option"
              value="approved"
            />
            <Translate
              attributes={{ label: "label.invested" }}
              component="option"
              value="invested"
            />
            <Translate
              attributes={{ label: "label.requested" }}
              value="requested"
              component="option"
            />
            <Translate
              attributes={{ label: "label.suspended" }}
              value="suspended"
              component="option"
            />
            <Translate
              attributes={{ label: "label.canceled" }}
              value="canceled"
              component="option"
            />
            <Translate
              attributes={{ label: "label.expired" }}
              value="expired"
              component="option"
            />
          </select>
          <Translate
            component="input"
            className="form-control mr-sm-2"
            attributes={{ placeholder: "button.search" }}
            aria-label="Search"
            value={this.state.product_title}
            onChange={e => this.setState({ product_title: e.target.value })}
          />

          <Translate
            content="button.search"
            component="button"
            className="btn btn-primary mr-sm-2"
            onClick={e => {
              e.preventDefault();
              this.props.getProductsList(
                this.props.pagination.currentPage,
                this.state.status,
                this.state.product_title
              );
            }}
          />
        </form>
        <div className="content-body">
          <table
            className="table tablesaw-stack"
            data-tablesaw-mode="swipe"
            data-tablesaw-minimap="data-tablesaw-minimap"
          >
            <thead>
              <tr>
                <th>
                  <Translate content="column.name" />
                </th>
                <th>
                  <Translate content="label.service" />
                </th>
                <th>
                  <Translate content="label.industries" />
                </th>
                <th>
                  <Translate content="column.minduration" />
                </th>
                <th>
                  <Translate content="column.maxduration" />
                </th>
                <th>
                  <Translate content="column.minimum_credit_amount" />
                </th>
                <th>
                  <Translate content="column.minimum_balance" />
                </th>
                <th>
                  <Translate content="column.status" />
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.data ? this.renderList(this.props.data) : null}
            </tbody>
          </table>
          <Pagination
            totalPage={totalPage}
            url={page => this.props.getProductsList(page, this.state.status)}
          />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.productsList,
    pagination: state.pagination,
    language: state.language
  };
}

export default connect(mapStateToProps, actions)(ProductsList);
