import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Subheader from "../general/Subheader";
import { getApplicationDetail } from "../../actions/application";
import Translate from "react-translate-component";
import { changeStatus } from "../../actions/changeStatus";
import { ToEuro } from "../general/CurrencyFormatter";
import { clearError, clearLoading } from "../../actions/clearError";
import { matchesInvestorStatus } from "../../_status";
import { downloadFile } from "../../actions/download";
import Moment from "react-moment";
import Loading from "../general/Loading";
import FileList from "../general/FileList";

class DetailCreditRequest extends Component {
  state = { detail: null, refresh: false };

  componentDidMount() {
    this.props.clearError();
    this.props.clearLoading();
    if (!this.props.location.state) {
      return this.props.history.push("/products");
    }
    let { pId, aId } = this.props.location.state;
    if (!pId || !aId) {
      const { appId, productId } = this.props.location.state;
      pId = productId;
      aId = appId;
    }
    this.props.getApplicationDetail(pId, aId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data !== prevProps.data) {
      this.setState({ detail: this.props.data.detail });
    }
    if (this.state.refresh !== prevState.refresh) {
      const { pId, aId } = this.props.location.state;
      this.props.getApplicationDetail(pId, aId);
    }
  }

  /**
   * Loop through collection to show objects display properties
   **/
  showCollections = collection => {
    if (collection.length === 0) {
      return <Translate content="placeholder.notAvailable" component="div" />;
    } else {
      return collection.map((object, index) => {
        return (
          <span key={index}>
            {object.name ? (
              object.name[this.props.language]
            ) : (
              <Translate content="placeholder.notAvailable" />
            )}{" "}
            <br />
          </span>
        );
      });
    }
  };

  showNameValuePair = (arrayOfObjects,multilingual=false) => {
    if (arrayOfObjects.length === 0) {
      return (
        <Translate
          content="placeholder.notAvailable"
          className="product__info"
          component="p"
        />
      );
    }
    return arrayOfObjects.map((object) => {
      return (
        <div className="col-3 col-md-6 col-sm-12 col-lg-3 p-0" key={object.id}>
          <div className="d-flex">
            <span className="mr-3">{multilingual?object.name[this.props.language]:false}</span>
            <span>{object.value}</span>
          </div>
        </div>
      );
    });
  };
  changeStatus = status => {
    const { pId, aId } = this.props.location.state;

    this.props.changeStatus(pId, aId, status, () =>
      this.setState({ refresh: !this.state.refresh })
    );
  };

  render() {
    if (this.state.detail) {
      const {
        requested_by,
        requested_on,
        amount,
        deadline,
        description,
        payment_after,
        sales,
        status,
        application_files,
        time_duration,
        collaterals,
        state,
        county,
        nda_requirement,
        service,
        industries,
        ratings
      } = this.state.detail;

      console.log("ratings", ratings);
      return (
        <Fragment>
          <Subheader heading={this.props.location.state.product} />
          <Loading />
          {status === "rejected" ? (
            <div className="alert alert-rejected">
              <Translate content="column.appreject" />
            </div>
          ) : null}

          {status === "accepted" ? (
            <div className="alert alert-success">
              <Translate content="column.appaccept" />
            </div>
          ) : null}
          <div className="content-body credit-request">
            <div className=" product-details row">
              <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                <div className="row">
                  <div className="col-4 product-info">
                    <h6>
                      <Translate content="label.state" />
                    </h6>
                    <span>{state ? state.name : null}</span>
                  </div>
                  <div className="col-4 product-info">
                    <h6>
                      <Translate content="label.county" />
                    </h6>
                    <span>{county ? county.name : null}</span>
                  </div>
                  <div className="col-4 product-info">
                    <h6>
                      <Translate content="column.credittype" />
                    </h6>
                    <span>
                      {service.name ? (
                        service.name[this.props.language]
                      ) : (
                        <Translate content="placeholder.notAvailable" />
                      )}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-4 product-info">
                    <h6>
                      <Translate content="label.industries" />
                    </h6>
                    <div className="d-flex flex-wrap justify-content-between flex-column">
                      {industries ? this.showCollections(industries) : null}
                    </div>
                  </div>

                  <div className="col-4 product-info">
                    <h6>
                      <Translate content="label.collaterals" />
                    </h6>
                    <span>{this.showNameValuePair(collaterals)}</span>
                  </div>
                  <div className="col-4 product-info">
                    <h6>
                      <Translate content="column.requestedby" />
                    </h6>
                    <div className="product-info-user">
                      {/* <img src="assets/img/investor-profile.jpg" alt="Investor profile picture" /> */}
                      {requested_by}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 product-info">
                    <h6>
                      <Translate content="label.deadline" />
                    </h6>
                    <span>
                      <Moment format="DD.MM.YYYY">{deadline}</Moment>
                    </span>
                  </div>
                  <div className="col-4 product-info">
                    <h6>
                      <Translate content="label.deadlineForPayment" />
                    </h6>
                    <span>
                      <Moment format="DD.MM.YYYY">{payment_after}</Moment>
                    </span>
                  </div>
                  <div className="col-4 product-info">
                    {/* <h6>Time Duration</h6> */}
                    <h6>
                      <Translate content="label.status" />
                    </h6>
                    <span>
                      <Translate
                        content={matchesInvestorStatus[status].translation_key}
                      />
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 reason-description product-info">
                    <h6>
                      <Translate content="label.reasons" />
                    </h6>
                    <span>{description}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 product-info">
                    <h6>
                      <Translate content="label.ratingForCredit" />
                    </h6>
                    {this.showNameValuePair(ratings,true)}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 product-details-right">
                <div className="row">
                  <div className="col-12">
                    {/* <h6>Requested amount of</h6> */}
                    <h6 className="product__title">
                      <Translate content="label.requestedamount" />
                    </h6>
                    <span className="product__info">
                      <ToEuro amount={amount} />
                    </span>
                  </div>
                  <div className="col-12 mt-3">
                    {/* <h6>States</h6> */}
                    <h6>
                      <Translate content="label.salesAmount" />
                    </h6>
                    <span>
                      <ToEuro amount={sales} />
                    </span>
                  </div>
                  <div className="col-12 mt-3">
                    <h6>
                      <Translate content="column.requeston" />
                    </h6>
                    <span>
                      <Moment format="DD.MM.YYYY">{requested_on}</Moment>
                    </span>
                  </div>
                  <div className="col-12 mt-3">
                    {/* <h6>Time Duration</h6> */}
                    <h6>
                      <Translate content="label.time" />
                    </h6>
                    <span>{time_duration} Monate</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row product-info-row">
              {!nda_requirement ? (
                <div className="product-info col-6 col-md-3">
                  <h6>
                    <Translate content="label.nda" />
                  </h6>
                  <span>
                    <Translate content="label.yes" />
                  </span>
                </div>
              ) : (
                <Fragment></Fragment>
              )}
            </div>

            <div className="product-info-files product-info mb-4">
              <h4 className="product__file-name">
                <Translate content="label.attachments" />
              </h4>
              <FileList documents={application_files} />
            </div>
          </div>
        </Fragment>
      );
    } else {
      return <Loading />;
    }
  }
}

function mapStateToProps(state) {
  return { data: state.applicationDetail, language: state.language };
}

export default connect(mapStateToProps, {
  getApplicationDetail,
  changeStatus,
  clearError,
  clearLoading,
  downloadFile
})(DetailCreditRequest);
