import { FETCH_RATINGS } from "../actions/types";

const initialState = {
  ratings: { data: [] }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_RATINGS:
      return {
        ...state,
        ratings: action.payload
      };

    default:
      return state;
  }
}
