import client from "./index";
import { routes } from "./../_api/routes";
import { ERROR, LOADING, LOADING_DONE } from "./types";
import { extractServerErrors } from "../_utils/validate";

export const signup = (detail, callback) => async dispatch => {
  try {
    dispatch({
      type: LOADING,
      payload: { URL: routes.signup, method: "post", data: detail }
    });
    localStorage.setItem("email", detail.email);
    await client.post(routes.signup, detail);
    callback();
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ERROR,
        payload:
          typeof e.response === "undefined"
            ? e.message
            : extractServerErrors(e.response.data)
      });
    }
  } finally {
    dispatch({
      type: LOADING_DONE,
      payload: { URL: routes.signup, method: "post", data: detail }
    });
  }
};
