import React from "react";

export default () => {
  return (
    <div className="container-full-height ct-container">
      <div className="main-content main-content--padded main-contnet--document">
        <div className="container-full-height text-centerd d-flex">
          <div className="content">
            <div className="logo-container">
              <img src="/assets/img/logo.png" alt="Pinhub Logo" />
            </div>
            <div className="page-header">
              <h1 className="page-title">Datenschutzhinweise</h1>
            </div>
            <div className="document-content">
              <div className="document-section">
                <p>
                  <strong>
                    Unser Umgang mit Ihren Daten und Ihre Rechte– Informationen
                    nach Artikeln 13, 14 und 21 der Datenschutz-Grundverordnung
                    (DSGVO)
                  </strong>
                </p>
                <p>Lieber Kunde,</p>
                <p>
                  nachfolgend informieren wir Sie über die Verarbeitung Ihrer
                  personenbezogenen Daten durch uns und die Ihnen nach den
                  datenschutzrechtlichen Regelungen zustehenden Ansprüche und
                  Rechte.
                </p>
                <p>
                  Welche Daten im Einzelnen verarbeitet und in welcher Weise
                  genutzt werden, richtet sich maßgeblich nach den beantragten
                  bzw. vereinbarten Dienstleistungen.
                </p>
              </div>

              <div className="document-section">
                <h2 className="document-title">
                  1. Wer ist für die Datenverarbeitung verantwortlich und an wen
                  kann ich mich wenden?
                </h2>
                <p>
                  Verantwortliche Stelle ist: <br />
                  piHub Private Investments GmbH <br />
                  Wilhelm-Leuschner-Strasse 14 <br />
                  60325 Frankfurt <br />
                  Telefon: 069 138 281 40 <br />
                  E-Mail-Adresse: info@pihub-pi.com <br />
                  Sie erreichen unseren betrieblichen Datenschutzbeauftragten
                  unter: <br />
                  Thomas Gutte Datenschutzberatung <br />
                  Hochstraße 2 <br />
                  65195 Wiesbaden <br />
                  Telefon: 0611 – 71186990 <br />
                  E-Mail-Adresse: thomas.gutte@gutte-datenschutz.de <br />
                </p>
              </div>

              <div className="document-section">
                <h2 className="document-title">
                  2. Welche Quellen und Daten nutzen wir?
                </h2>
                <p>
                  Wir verarbeiten personenbezogene Daten, die wir im Rahmen
                  unserer Geschäftsbeziehung von Ihnen erhalten. Zudem
                  verarbeiten wir – soweit für die Erbringung unserer
                  Dienstleistungen erforderlich – personenbezogene Daten, die
                  wir von anderen Unternehmen (z.B. der SCHUFA) zulässigerweise
                  (z.B. zur Ausführung von Aufträgen, zur Erfüllung von
                  Verträgen oder aufgrund einer von Ihnen erteilten
                  Einwilligung) erhalten haben. Zum anderen verarbeiten wir
                  personenbezogene Daten, die wir aus öffentlich zugänglichen
                  Quellen (z.B. Schuldnerverzeichnisse, Grundbücher, Handels-
                  und Vereinsregister, Presse, Medien) zulässigerweise gewonnen
                  haben und verarbeiten dürfen.
                </p>

                <p>
                  {" "}
                  Relevante personenbezogene Daten sind Personalien (Name,
                  Adresse und andere Kontaktdaten, Geburtstag und -ort und
                  Staatsangehörigkeit), Legitimationsdaten (z.B. Ausweisdaten)
                  und Authentifikationsdaten (z.B. Unterschriftprobe). Darüber
                  hinaus können dies auch Auftragsdaten (z. B. Zahlungsauftrag,
                  Verträge usw.), Daten aus der Erfüllung unserer vertraglichen
                  Verpflichtungen (z. B. Umsatzdaten im Zahlungsverkehr,
                  Kreditrahmen, Produktdaten [(z. B. Einlagen-, Kredit- und
                  Depotgeschäft]), Informationen über Ihre finanzielle Situation
                  (z. B. Bonitätsdaten, Scoring-/Ratingdaten, Herkunft von
                  Vermögenswerten), Werbe- und Vertriebsdaten (inklusive
                  Werbescores), Dokumentationsdaten (z. B. Beratungsprotokoll),
                  Registerdaten, Daten über Ihre Nutzung von unseren angebotenen
                  Telemedien (z. B. Zeitpunkt des Aufrufs unserer Webseiten,
                  Apps oder Newsletter, angeklickte Seiten von uns bzw.
                  Einträge) sowie andere mit den genannten Kategorien
                  vergleichbare Daten sein.
                </p>
              </div>

              <div className="document-section">
                <h2 className="document-title">
                  3. Wofür verarbeiten wir Ihre Daten (Zweck der Verarbeitung)
                  und auf welcher Rechtsgrundlage?
                </h2>
                <p>
                  Wir verarbeiten personenbezogene Daten im Einklang mit den
                  Bestimmungen der Europäischen Datenschutz-Grundverordnung
                  (DSGVO) und dem Bundesdatenschutzgesetz (BDSG):
                </p>
                <div className="document-sub-section">
                  <h3 className="document-sub-title">
                    3.1. Zur Erfüllung von vertraglichen Pflichten (Artikel 6
                    Abs. 1b DSGVO)
                  </h3>
                  <p>
                    Die Verarbeitung personenbezogener Daten (Artikel 4 Nr. 2
                    DSGVO) erfolgt zur Erbringung und Vermittlung unserer
                    Dienstleistung, insbesondere zur Durchführung unserer
                    Verträge oder vorvertraglicher Maßnahmen mit Ihnen und der
                    Ausführung Ihrer Aufträge, sowie aller mit dem Betrieb und
                    der Verwaltung eines Unternehmens erforderlichen
                    Tätigkeiten.
                  </p>
                  <p>
                    Die Zwecke der Datenverarbeitung richten sich in erster
                    Linie nach dem konkreten Produkt (z. B. Kredit Vermittlung,
                    Beratung) und können unter anderem Bedarfsanalysen,
                    Beratung, sowie die Durchführung von Transaktionen umfassen.
                  </p>
                  <p>
                    Die weiteren Einzelheiten zum Zweck der Datenverarbeitung
                    können Sie den jeweiligen Vertragsunterlagen und
                    Geschäftsbedingungen entnehmen.
                  </p>
                </div>

                <div className="document-sub-section">
                  <h3 className="document-sub-title">
                    3.2. Im Rahmen der Interessenabwägung (Artikel 6 Abs. 1f
                    DSGVO)
                  </h3>
                  <p>
                    Soweit erforderlich, verarbeiten wir Ihre Daten über die
                    eigentliche Erfüllung des Vertrages hinaus zur Wahrung
                    berechtigter Interessen von uns oder Dritten wie
                    beispielsweise in den folgenden Fällen:
                  </p>
                  <ul>
                    <li>
                      Gewährleistung der IT-Sicherheit und des IT-Betriebs;
                    </li>
                    <li>
                      Prüfung und Optimierung von Verfahren zur Bedarfsanalyse
                      und direkter Kundenansprache;
                    </li>
                    <li>
                      Werbung oder Markt- und Meinungsforschung, soweit sie der
                      Nutzung Ihrer Daten nicht widersprochen haben;
                    </li>
                    <li>
                      Geltendmachung rechtlicher Ansprüche und Verteidigung bei
                      rechtlichen Streitigkeiten;
                    </li>
                    <li>
                      Maßnahmen zur Gebäude- und Anlagensicherheit (z.B.
                      Zutrittskontrollen);
                    </li>
                    <li>Maßnahmen zur Sicherstellung des Hausrechts;</li>
                    <li>
                      Maßnahmen zur Geschäftssteuerung und Weiterentwicklung von
                      Dienstleistungen und Produkten;
                    </li>
                    <li>
                      Konsultation von und Datenaustausch mit Auskunfteien (z.B.
                      SCHUFA) zur Ermittlung von Bonitäts- bzw. Ausfallrisiken;
                    </li>
                    <li>Verhinderung und Aufklärung von Straftaten.</li>
                  </ul>
                </div>

                <div className="document-sub-section">
                  <h3 className="document-sub-title">
                    3.3. Aufgrund Ihrer Einwilligung (Artikel 6 Abs. 1a DSGVO)
                  </h3>
                  <p>
                    Soweit Sie uns eine Einwilligung zur Verarbeitung von
                    personenbezogenen Daten für bestimmte Zwecke (z.B.
                    Weitergabe von Daten, Auswertung von Nutzerdaten für
                    Marketingzwecke) erteilt haben, ist die Rechtmäßigkeit
                    dieser Verarbeitung auf Basis Ihrer Einwilligung gegeben.
                    Eine erteilte Einwilligung kann jederzeit widerrufen werden.
                    Dies gilt auch für den Widerruf von
                    Einwilligungserklärungen, die – wie beispielsweise die
                    SCHUFA-Klausel – vor der Geltung der DSGVO, also vor dem 25.
                    Mai 2018, uns gegenüber erteilt worden sind.
                  </p>
                  <p>
                    Bitte beachten Sie, dass der Widerruf erst für die Zukunft
                    wirkt. Verarbeitungen, die vor dem Widerruf erfolgt sind,
                    sind davon nicht betroffen.
                  </p>
                </div>

                <div className="document-sub-section">
                  <h3 className="document-sub-title">
                    3.4. Aufgrund gesetzlicher Vorgaben (Artikel 6 Abs. 1c
                    DSGVO) oder im öffentlichen Interesse (Artikel 6 Abs. 1e
                    DSGVO)
                  </h3>
                  <p>
                    Zudem unterliegen wir als Bank diversen rechtlichen
                    Verpflichtungen, das heißt gesetzlichen Anforderungen (z. B.
                    Kreditwesengesetz, Geldwäschegesetz,
                    Wertpapierhandelsgesetz, Steuergesetze) sowie
                    bankaufsichtsrechtlichen Vorgaben (z. B. der Europäischen
                    Zentralbank, der Europäischen Bankenaufsicht, der Deutschen
                    Bundesbank und der Bundesanstalt für
                    Finanzdienstleistungsaufsicht). Zu den Zwecken der
                    Verarbeitung gehören unter anderem die
                    Kreditwürdigkeitsprüfung, die Identitäts- und Altersprüfung,
                    Betrugs- und Geldwäscheprävention, die Erfüllung
                    steuerrechtlicher Kontroll- und Meldepflichten sowie die
                    Bewertung und Steuerung von Risiken.
                  </p>
                </div>
              </div>

              <div className="document-section">
                <h2 className="document-title">4. Wer bekommt meine Daten?</h2>
                <div className="document-sub-section">
                  <h3 className="document-sub-title">
                    4.1. Nutzungsberechtigung
                  </h3>
                  <p>
                    Innerhalb unseres Unternehmens erhalten diejenigen Stellen
                    Zugriff auf Ihre Daten, die diese zur Erfüllung unserer
                    vertraglichen und gesetzlichen Pflichten brauchen. Auch von
                    uns eingesetzte Auftragsverarbeiter (Artikel 28 DSGVO)
                    können zu diesen genannten Zwecken Daten erhalten. Dies sind
                    Unternehmen in den Kategorien IT-Dienstleistungen, Logistik,
                    Druckdienstleistungen, Telekommunikation, Inkasso, Beratung
                    und Consulting sowie Vertrieb und Marketing. Des Weiteren
                    erhalten insbesondere Finanzierer, welche wir aufgrund Ihrer
                    Informationen als potentielle Kredit- oder
                    Finanzierungsgeber identifiziert haben, wie Banken,
                    Kreditfonds oder Family Offices Ihre Daten.
                  </p>
                  <p>
                    Im Hinblick auf die Datenweitergabe an Empfänger außerhalb
                    unseres Unternehmens ist zu beachten, dass wir Informationen
                    über Sie nur weitergeben, wenn gesetzliche Bestimmungen dies
                    gebieten, Sie eingewilligt haben oder wir zur Erteilung
                    einer Auskunft befugt sind.
                  </p>
                  <p>
                    Unter diesen Voraussetzungen können Empfänger
                    personenbezogener Daten z.B. sein:
                  </p>
                  <ul>
                    <li>
                      Öffentliche Stellen und Institutionen (z. B. Deutsche
                      Bundesbank, Bundesanstalt für
                      Finanzdienstleistungsaufsicht, Europäische
                      Bankenaufsichtsbehörde, Europäische Zentralbank,
                      Finanzbehörden) bei Vorliegen einer gesetzlichen oder
                      behördlichen Verpflichtung.
                    </li>
                    <li>
                      Andere Kredit- und Finanzdienstleistungsinstitute oder
                      vergleichbare Einrichtungen, an die wir zur Durchführung
                      der Geschäftsbeziehung mit Ihnen personenbezogene Daten
                      übermitteln (je nach Vertrag: z. B. Korrespondenzbanken,
                      Depotbanken, Börsen, Auskunfteien).
                    </li>
                  </ul>
                  <p>
                    Weitere Datenempfänger können diejenigen Stellen sein, für
                    die Sie uns Ihre Einwilligung zur Datenübermittlung erteilt
                    bzw. für die Sie uns vom Bankgeheimnis gemäß Vereinbarung
                    oder Einwilligung befreit haben.
                  </p>
                </div>
              </div>

              <div className="document-section">
                <h2 className="document-title">
                  5. Wie lange werden meine Daten gespeichert?
                </h2>
                <p>
                  Soweit erforderlich, verarbeiten und speichern wir Ihre
                  personenbezogenen Daten für die Dauer unserer
                  Geschäftsbeziehung, was beispielsweise auch die Anbahnung und
                  die Abwicklung eines Vertrages umfasst.
                </p>
                <p>
                  Darüber hinaus unterliegen wir verschiedenen Aufbewahrungs-
                  und Dokumentationspflichten, die sich unter anderem aus dem
                  Handelsgesetzbuch (HGB) und der Abgabenordnung (AO) ergeben.
                  Die dort vorgegebenen Fristen zur Aufbewahrung bzw.
                  Dokumentation betragen zwei bis zehn Jahre.
                </p>
                <p>
                  Schließlich beurteilt sich die Speicherdauer auch nach den
                  gesetzlichen Verjährungsfristen, die zum Beispiel nach den §§
                  195 ff. des Bürgerlichen Gesetzbuches (BGB) in der Regel drei
                  Jahre, in gewissen Fällen aber auch bis zu dreißig Jahre
                  betragen können.
                </p>
              </div>

              <div className="document-section">
                <h2 className="document-title">
                  6. Werden Daten in ein Drittland oder an eine internationale
                  Organisation übermittelt?
                </h2>
                <p>
                  Eine Datenübermittlung in Drittstaaten (Staaten außerhalb des
                  Europäischen Wirtschaftsraums – EWR) findet nur statt, soweit
                  dies zur Ausführung Ihrer Aufträge erforderlich, gesetzlich
                  vorgeschrieben ist oder Sie uns Ihre Einwilligung erteilt
                  haben. Über Einzelheiten werden wir Sie, sofern gesetzlich
                  vorgegeben, gesondert informieren.
                </p>
              </div>

              <div className="document-section">
                <h2 className="document-title">
                  7. Welche Datenschutzrechte habe ich?
                </h2>
                <p>
                  Jede betroffene Person hat das Recht auf Auskunft nach Artikel
                  15 DSGVO, das Recht auf Berichtigung nach Artikel 16 DSGVO,
                  das Recht auf Löschung nach Artikel 17 DSGVO, das Recht auf
                  Einschränkung der Verarbeitung nach Artikel 18 DSGVO sowie das
                  Recht auf Datenübertragbarkeit aus Artikel 20 DSGVO. Beim
                  Auskunftsrecht und beim Löschungsrecht gelten die
                  Einschränkungen nach §§ 34 und 35 BDSG. Darüber hinaus besteht
                  ein Beschwerderecht bei einer Datenschutzaufsichtsbehörde
                  (Artikel 77 DSGVO i. V. m. § 19 BDSG).
                </p>
              </div>

              <div className="document-section">
                <h2 className="document-title">
                  8. Besteht eine Pflicht zur Bereitstellung von Daten?
                </h2>
                <p>
                  Im Rahmen unserer Geschäftsbeziehung müssen Sie nur diejenigen
                  personenbezogenen Daten bereitstellen, die für die Begründung,
                  Durchführung und Beendigung einer Geschäftsbeziehung
                  erforderlich sind oder zu deren Erhebung wir gesetzlich
                  verpflichtet sind. Ohne diese Daten werden wir in der Regel
                  den Abschluss des Vertrages oder die Ausführung des Auftrages
                  ablehnen müssen oder einen bestehenden Vertrag nicht mehr
                  durchführen können und ggf. beenden müssen.
                </p>
                <p>
                  Insbesondere sind wir nach den geldwäscherechtlichen
                  Vorschriften verpflichtet, Sie vor der Begründung der
                  Geschäftsbeziehung beispielsweise anhand Ihres
                  Personalausweises zu identifizieren und dabei Ihren Namen,
                  Geburtsort, Geburtsdatum, Staatsangehörigkeit sowie Ihre
                  Wohnanschrift zu erheben. Damit wir dieser gesetzlichen
                  Verpflichtung nachkommen können, haben Sie uns nach dem
                  Geldwäschegesetz die notwendigen Informationen und Unterlagen
                  zur Verfügung zu stellen und sich im Laufe der
                  Geschäftsbeziehung ergebende Änderungen unverzüglich
                  anzuzeigen. Sollten Sie uns die notwendigen Informationen und
                  Unterlagen nicht zur Verfügung stellen, dürfen wird die von
                  Ihnen gewünschte Geschäftsbeziehung nicht aufnehmen.
                </p>
              </div>

              <div className="document-section">
                <h2 className="document-title">
                  9. Inwieweit gibt es eine automatisierte Entscheidungsfindung
                  im Einzelfall?
                </h2>
                <p>
                  Zur Begründung und Durchführung der Geschäftsbeziehung nutzen
                  wir grundsätzlich keine vollautomatisierte
                  Entscheidungsfindung gemäß Artikel 22 DSGVO. Sollten wir diese
                  Verfahren in Einzelfällen einsetzen, werden wir Sie hierüber
                  gesondert informieren, sofern dies gesetzlich vorgegeben ist.
                </p>
              </div>

              <div className="document-section">
                <h2 className="document-title">
                  10. Inwieweit werden meine Daten für die Profilbildung
                  (Scoring) genutzt?
                </h2>
                <p>
                  Wir nutzen grundsätzlich keine Profilbildung gemäß Artikel 22
                  DSGVO. Sollten wir dieses Verfahren in Einzelfällen einsetzen,
                  werden wir Sie hierüber gesondert informieren, sofern dies
                  gesetzlich vorgegeben ist. Oder
                </p>
                <p>
                  Wir verarbeiten teilweise Ihre Daten automatisiert mit dem
                  Ziel, bestimmte persönliche Aspekte zu bewerten (Profiling).
                  Wir setzen Profiling beispielsweise in folgenden Fällen ein:
                </p>
                <ul>
                  <li>
                    Aufgrund gesetzlicher und regulatorischer Vorgaben sind wir
                    zur Bekämpfung von Geldwäsche, Terrorismusfinanzierung und
                    vermögensgefährdenden Straftaten verpflichtet. Dabei werden
                    auch Datenauswertungen (u. a. im Zahlungsverkehr)
                    vorgenommen. Diese Maßnahmen dienen zugleich auch Ihrem
                    Schutz.
                  </li>
                  <li>
                    Um Sie zielgerichtet über Produkte informieren und beraten
                    zu können, setzen wir Auswertungsinstrumente ein. Diese
                    ermöglichen eine bedarfsgerechte Kommunikation und Werbung
                    einschließlich Markt- und Meinungsforschung.
                  </li>
                  <li>
                    Im Rahmen der Beurteilung Ihrer Kreditwürdigkeit nutzen wir
                    das Scoring. Dabei wird die Wahrscheinlichkeit berechnet,
                    mit der ein Kunde seinen Zahlungsverpflichtungen
                    vertragsgemäß nachkommen wird. In die Berechnung können
                    beispielsweise Bilanzen, Gewinn- und Verlustrechnungen,
                    Lebensläufe, Erfahrungen aus der bisherigen
                    Geschäftsbeziehung, vertragsgemäße Rückzahlung früherer
                    Kredite sowie Informationen von Kreditauskunfteien und
                    Ratingagenturen einfließen. Das Scoring beruht auf einem
                    mathematisch-statistisch anerkannten und bewährten
                    Verfahren. Die errechneten Scorewerte unterstützen uns bei
                    der Entscheidungsfindung im Rahmen von Produktabschlüssen
                    und gehen in das laufende Risikomanagement mit ein.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
