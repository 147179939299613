import React, { Component } from "react";
import { connect } from "react-redux";
import { downloadFile } from "../../actions/download";
import Translate from "react-translate-component";

class FileList extends Component {
  render() {
    return (
      <div>
        {this.props.documents.map((doc, index) => (
          <div className="file mb-2" key={index}>
            <span className="file-name">
              {doc.file_name}
              <button
                className="btn btn-link"
                onClick={event => {
                  event.preventDefault();
                  this.props.downloadFile(
                    {
                      file_list: [
                        {
                          file_name: doc.file_name,
                          file_path: doc.path
                        }
                      ]
                    },
                    doc.file_name
                  );
                }}
              >
                Download
              </button>
              {this.props.onDocumentsChange ? 
              <i className="bx bx-x-circle" onClick={ e => {
                  const removedDocuments = this.props.documents.filter(document => document.id !== doc.id );
                  this.props.onDocumentsChange(removedDocuments);
                  
                }
              }></i>
            : ""}
            </span>
          </div>
        ))}
        {this.props.documents.length !== 0 ? (
          <button
            className="btn btn-link"
            onClick={e => {
              e.preventDefault();
              this.props.downloadFile(
                {
                  file_list: this.props.documents.map(doc => ({
                    file_name: doc.file_name,
                    file_path: doc.path
                  }))
                },
                "attachments.zip"
              );
            }}
          >
            <Translate content="button.downloadAll" />
          </button>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default connect(null, { downloadFile })(FileList);
