import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getNotificationCount } from "../../actions/notification";
import { logout } from "../../actions/login";
import Translate from "react-translate-component";
import Loading from "./Loading";
import { getProfile } from "../../actions/profile";
import { SidebarContext } from "./SidebarContext";
import LanguageFlags from "./LanguageFlags";

class Header extends Component {

  componentDidMount() {
    this.props.getNotificationCount();
    this.props.getProfile();
  }

  static contextType = SidebarContext;

  render() {
    if (!this.props.profile) {
      return <Loading />;
    } else {
      const { company_logo_link } = this.props.profile;
      return (
        <header className="site-header align-items-center">
          <div className="logo-container">
            <div id="hamburger" onClick={this.context.toggleSidebar}>
              <div className="hamburger-bar"></div>
            </div>
            <img src="/assets/img/logo.png" alt="Pinhub Logo" />
          </div>
          <nav className="header-actions">
            <ul>
             <LanguageFlags />

              <li className="header-actions__item">
                <Link className="header-notification" to="/notifications">
                  <i className="bx bx-bell" />
                  <span className="notification-count">{this.props.count}</span>
                </Link>
              </li>

              <div className="dropdown">
                <button
                  to=""
                  className=" dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    className="dropdown-user"
                    src={
                      company_logo_link
                        ? company_logo_link
                        : "/assets/img/user.png"
                    }
                    alt="user_image"
                  />
                </button>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownMenuButton"
                >
                  <Link className="dropdown-item" to="/user/profile">
                    <Translate content="label.profile" />
                  </Link>
                  <Link className="dropdown-item" to="/user/edit-profile">
                    <Translate content="label.editProfile" />
                  </Link>
                  <Link className="dropdown-item" to="/change-password">
                    <Translate content="label.resetPassword" />
                  </Link>
                  <span
                    className="dropdown-item"
                    onClick={() => {
                      this.props.logout(() => this.props.history.push("/"));
                    }}
                  >
                    <Translate content="label.logout" />
                  </span>
                </div>
              </div>
            </ul>
          </nav>
        </header>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    count: state.notificationCount,
    profile: state.profile
  };
}

export default connect(mapStateToProps, {
  getNotificationCount,
  logout,
  getProfile
})(withRouter(Header));
