import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getNotificationList, markAsRead } from "../../actions/notification";
import Translate from "react-translate-component";
import Loading from "../general/Loading";

class Notifications extends Component {
  state = { refresh: false };

  componentDidMount() {
    this.props.getNotificationList(1);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.refresh !== this.state.refresh) {
      this.props.getNotificationList(1);
    }
  }

  renderNotification = notifications => {
    if (notifications.length === 0) {
      return <Translate content="label.youdont" />;
    }
    return notifications.map((notification, index) => {
      return (
        <Fragment key={index}>
          <li
            className="notification-item d-flex flex-row align-items-top mb-3 pb-3"
            onClick={() =>
              this.markAsRead(notification.id, notification.is_read)
            }
          >
            <div
              className={`status ${
                notification.is_read === 0 ? "success" : "wait"
              } mr-4`}
            />
            <div className="title">
              <p className="wait">
                {notification[this.props.language].notification}
              </p>
              <div className="time">
                {notification[this.props.language].ago}
              </div>
            </div>
          </li>
        </Fragment>
      );
    });
  };
  markAsRead = (id, is_read) => {
    if (is_read === 0) {
      this.props.markAsRead(id, () => {
        this.setState({ refresh: !this.state.refresh });
      });
    }
  };

  render() {
    return (
      <Fragment>
        <div className="content-head">
          <div className="content-head-left">
            <h1 className="content-head__title">Notifications</h1>
          </div>
        </div>
        <div className="content-body mt-2">
          <Loading />
          <ul className="notification p-0">
            {this.props.list && this.props.list.notificationList
              ? this.renderNotification(this.props.list.notificationList)
              : null}
          </ul>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    list: state.notificationList,
    language: state.language
  };
}

export default connect(mapStateToProps, { getNotificationList, markAsRead })(
  Notifications
);
