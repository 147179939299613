import jwt_decode from "jwt-decode";
import client from "./index";
import { routes } from "./../_api/routes";
import { AUTH_ERROR, AUTH_USER, SCOPE, LOADING, LOADING_DONE } from "./types";

const { BroadcastChannel } = require("broadcast-channel");

const logoutChannel = new BroadcastChannel("");

export const signin = ({ email, password }, callback) => async dispatch => {
  try {
    dispatch({
      type: LOADING,
      payload: { URL: routes.login, method: "post", data: { email, password } }
    });
    const response = await client.post(routes.login, {
      email,
      password
    });
    const { scopes } = jwt_decode(response.data.message.token);
    const  firstTimeLogin  = response.data.message.first_time_login;
    let verfied;
    if (scopes[0] === "unconfirmed_scope") {
      verfied = false;
    } else {
      verfied = true;
    }
    localStorage.setItem("token", response.data.message.token);
    sessionStorage.setItem("profileComplete", response.data.message.profile_complete)
    dispatch({
      type: SCOPE,
      payload: verfied
    });
    dispatch({
      type: AUTH_USER,
      payload: response.data.message.token
    });
    dispatch({
      type: LOADING_DONE,
      payload: { URL: routes.login, method: "post", data: { email, password } }
    });
    callback(firstTimeLogin);
  } catch (e) {
    dispatch({
      type: AUTH_ERROR,
      payload: `${e.response.data.message}`
    });
    dispatch({
      type: LOADING_DONE,
      payload: { URL: routes.login, method: "post", data: { email, password } }
    });
  }
};

export const logout = callback => async dispatch => {
  try {
    logoutChannel.postMessage({
      userId: "",
      payload: {
        type: "LOGOUT"
      }
    });

    const response = await client.get(routes.logout);
    dispatch({
      type: AUTH_USER,
      payload: undefined
    });
    if (response) {
      localStorage.removeItem("token");
      callback();
    } else {
    }
  } catch (error) {}
};

export const logoutAllTabsEventListner = () => async dispatch => {
  logoutChannel.onmessage = data => {
    if (data.payload.type === "LOGOUT") {
      localStorage.removeItem("token");
      window.location.href = window.location.origin + "/login";
    }
    logoutChannel.close();
  };
};
